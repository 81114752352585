import './index.scss';

import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import namecase from 'namecase';
import axios from 'axios';
import { Modal, Tooltip, message } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import cloneDeep from 'lodash/cloneDeep';
import {
  handleCrmIconsAfterIntegration,
  handleCrmLabels,
} from '../../utils/crmMethods';
import {
  isEqual,
  isLowerCase,
  splitNumberWithCommas,
  titleCase,
  getMimEvent,
  getMultipleValueSelected,
  formatEventName,
  getDateRangeFromPeriod,
  getTeamFileLookup,
} from '../../utils/general';
import { requestWithAuth } from '../../services/api';
import {
  companyViablityRanges,
  FunctionFlags,
  interests,
  salaryFilter,
  wealthScoreRanges,
} from '../../utils/constants';

import {
  isPremiumWealthTrigger,
  hasEntitlement,
} from '../../utils/entitlements';

import withRouter from '../../hooks/withRouter';
import { copyUserFilter } from '../../utils/copyUserFilter';
import { getBodyParams } from '../../utils/getBodyParams';
import ExportTable from '../ExportTable';
import HelpIcon from '../../assets/icons/help-icon.svg?react';
import RowExtra from '../RowExtra';
import LoaderAnimation from '../AidLoader';
import BlurredContent from '../BlurredContent';

let source;
message.config({
  top: 80,
});
const sortInfo = {
  column: '',
  order: '',
};
let rowKeyArray = [];
const PeopleViewModal = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const flags = useFlags();
    const entitlementsActive = flags && flags.dntfd9919GtmEntitlements;

    const { onCancel, showCompanyProfile } = props;
    const [crmValue, setCrmValue] = useState('');
    const [eventTrigger, setEventTrigger] = useState(null);
    const [blurContent, setBlurContent] = useState(entitlementsActive);

    const [isLoading, setIsLoading] = useState(false);
    const [isCrmCredentials, setisCrmCredentials] = useState(null);
    const [networkListData, setNetworkListData] = useState([]);
    const [networkListDataCount, setNetworkListDataCount] = useState(0);
    const [listPagination, setListPagination] = useState({
      total: 0,
      pageSize: 100,
      current: 1,
    });
    const [selectedProfileIds, setSelectedProfileIds] = useState([]);
    const [exportFromRow, setExportFromRow] = useState(null);

    useEffect(() => {
      if (listPagination.total === 0) return;
      fetchListData();
    }, [listPagination.pageSize, listPagination.current]);

    useEffect(() => {
      setCrmValue(props.dataStore.currentUser.crm);
    }, [props.dataStore.currentUser.crm]);

    useEffect(() => {
      getFilterFromN2SummaryChart();
    }, []);

    useEffect(() => {
      setBlurContent(
        entitlementsActive &&
          isPremiumWealthTrigger(eventTrigger) &&
          !hasEntitlement(
            props.dataStore.currentUser,
            FunctionFlags.premium_wealth_triggers,
          ),
      );
    }, [entitlementsActive, eventTrigger, props.dataStore.currentUser]);

    const fetchListData = () => {
      const params = {
        page_size: listPagination.pageSize,
        from: (listPagination.current - 1) * listPagination.pageSize,
      };
      if (sortInfo.column && sortInfo.order) {
        params.sort_key = sortInfo.column;
        params.sort_order = sortInfo.order;
      }
      setIsLoading(true);

      if (source) source.cancel('Operation canceled by the user.');

      const { CancelToken } = axios;
      source = CancelToken.source();

      let body = {};
      body = getBodyParams(
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        props.dataStore.superConnectorFilter,
        props.dataStore.teamFilters,
        props.dataStore.currentUser,
        props.dataStore.mappedRelationshipsFilter,
        props.modalDataStore.profileModal,
        props.dataStore.groupByValues,
      );
      if (props.dataStore.quick_search)
        params.quick_search = props.dataStore.quick_search;

      if (props.dataStore.networkFilters.filter_type) {
        params.filter_type = props.dataStore.networkFilters.filter_type;
      }

      if (typeof props.dataStore.summaryFilter.ownerTypeId === 'number') {
        params.auto_second_degree = 0;
      }
      if (props.dataStore.summaryFilter.triggers_view) params.triggers_view = 1;

      requestWithAuth(
        'network-list-new',
        'POST',
        params,
        body,
        source.token,
        'progress_people_list',
      ).then((response) => {
        if (!response) {
          setIsLoading(false);
          return;
        }
        const paginationLimit = response.pagination_limit || 10000;
        setListPagination((prevState) => ({
          ...prevState,
          total:
            response.count > paginationLimit ? paginationLimit : response.count,
        }));
        setNetworkListDataCount(response.count);
        rowKeyArray = [];
        setNetworkListData(response.results);
        setIsLoading(false);
      });
    };

    function showPersonProfile(b2bId, b2cId) {
      // profile clicked
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }

      props.modalDataStore.resetCompanyProfileModal();
      props.modalDataStore.resetProfileModal();
      const { profileModal } = props.modalDataStore;
      profileModal.from = 'n2';
      profileModal.b2bId = b2bId;
      profileModal.b2cId = b2cId;
      profileModal.mimpersonId = '';
      profileModal.eventsId = '';
      profileModal.isVisible = true;

      props.modalDataStore.trackModal.push({
        modal: 'profile',
        data: {
          from: 'n2',
          b2bId,
          b2cId,
        },
      });
    }

    const getFilterFromN2SummaryChart = () => {
      props.dataStore.resetNetworkFilters();
      const filterId = props.dataStore.summaryFilter.selectedId;
      if (filterId !== -1) {
        props.dataStore.networkFilters.selectedIdValue = filterId;
        if (props.globalDataStore.multipleFilters.filterList.length > 0) {
          props.globalDataStore.multipleFilters.filterList.map((filter) => {
            if (filterId === filter.value) {
              saveUserFilter(
                filter.filters,
                filter.label,
                filter.is_active,
                filter.email_alert,
                filter.separate_email_alert,
                filter.filter_type,
                true,
              );
            }
          });
        }
      }

      const {
        id,
        item,
        ownerTypeId,
        import_source,
        file_imports,
        team_file_imports,
        quickSearchRI,
        allTeamFiles = [],
        isBoxClicked,
        filterType,
        opportunitiesPeriod,
        m2_date,
      } = props.dataStore.summaryFilter;

      setEventTrigger(item);

      if (opportunitiesPeriod) {
        /** Click from opportunities box in Dashboard */
        props.dataStore.networkFilters.m2_date =
          getDateRangeFromPeriod(opportunitiesPeriod);
        props.dataStore.networkFilters.filter_type = filterType;
        props.dataStore.networkFilters.import_source = import_source || [];
        props.dataStore.networkFilters.file_imports = file_imports || [];
        props.dataStore.networkFilters.team_file_imports =
          team_file_imports || [];
        // if (
        //   props.dataStore.lookUpLists.teamMemberIds &&
        //   props.dataStore.lookUpLists.teamMemberIds.length > 0
        // )
        //   props.dataStore.teamFilters.team_ids =
        //     props.dataStore.lookUpLists.teamMemberIds;
        fetchListData();
        return;
      }

      if (quickSearchRI) {
        props.dataStore.quick_search = quickSearchRI;
      }

      if (typeof ownerTypeId === 'number') {
        if (props.dataStore.lookUpLists.team) {
          const memberList = [];
          props.dataStore.lookUpLists.team.map((team) => {
            if (team.id === ownerTypeId) {
              team.users.map((members) => {
                memberList.push(members.id);
              });
            }
          });
          props.dataStore.teamFilters.team_ids = memberList;
        }
      } else {
        switch (ownerTypeId) {
          case 'first':
            props.dataStore.teamFilters.team_ids = [
              props.dataStore.currentUser.ownerId,
            ];
            props.dataStore.networkFilters.filter_type = 'first_degree';
            break;
          case 'second':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'second_degree';
            break;
          case 'combined':
            props.dataStore.teamFilters.team_ids =
              props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'combined';
            break;
          case 'all':
            // props.dataStore.teamFilters.team_ids =
            //   props.dataStore.lookUpLists.teamMemberIds;
            props.dataStore.networkFilters.filter_type = 'all';
            break;
          default:
            break;
        }
      }

      props.dataStore.networkFilters.import_source = import_source || [];
      props.dataStore.networkFilters.file_imports = file_imports || [];
      props.dataStore.networkFilters.team_file_imports =
        team_file_imports || [];
      switch (id) {
        case 'industry':
        case 'location_city':
        case 'schools':
        case 'location_state':
          /**  check the item already in networkFilters
                         add only if it doesn't contain */
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          props.dataStore.networkFilters[id].push({
            label: item,
            value: item,
          });
          break;
        case 'home_postal_code':
          props.dataStore.networkFilters.home_postal_code = {
            key: 'exact',
            value: [item, item],
          };
          break;

        case 'org_name':
        case 'title_rank':
          /**  check the item already in networkFilters
                         add only if it doesn't contain */
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some(
              (obj) => obj.value === item.value,
            )
          )
            break;
          props.dataStore.networkFilters[id].push(item);
          break;
        case 'inferred_salary_range':
          /**  check the item already in networkFilters
                         add only if it doesn't contain */
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          salaryFilter.map((salary) => {
            if (salary.label === item)
              props.dataStore.networkFilters.inferred_salary_range.push(salary);
            return '';
          });
          break;

        case 'stock_performance':
          /**  check the item already in networkFilters
                         add only if it doesn't contain */
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          companyViablityRanges.map((employee) => {
            if (employee.label === item)
              props.dataStore.networkFilters.stock_performance.push(employee);
            return '';
          });
          break;

        case 'interests': {
          /**  check the item already in networkFilters
                         add only if it doesn't contain */
          if (
            props.dataStore.networkFilters[id] &&
            props.dataStore.networkFilters[id].some((obj) => obj.value === item)
          )
            break;
          const interestsVal = interests.find((obj) => obj.label === item);
          if (interestsVal)
            props.dataStore.networkFilters.interests.push({
              label: item,
              value: interestsVal.value,
            });

          break;
        }
        case 'tags': {
          /**  check the item already in networkFilters
                         add only if it doesn't contain */
          const tagObject = props.dataStore.userTags.find((obj) => {
            return obj.tag === item;
          });
          if (
            !props.dataStore.networkFilters.tags_checked ||
            !Array.isArray(props.dataStore.networkFilters.tags_checked)
          )
            props.dataStore.networkFilters.tags_checked = [];
          else if (
            props.dataStore.networkFilters.tags_checked &&
            props.dataStore.networkFilters.tags_checked.some(
              (obj) => obj.value === tagObject.id,
            )
          )
            break;
          props.dataStore.networkFilters.tags_checked.push(tagObject.id);
          break;
        }
        case 'team_ids':
          props.dataStore.teamFilters.team_ids = item;
          if (isBoxClicked) break;
          if (ownerTypeId) {
            if (item[0] === props.dataStore.currentUser.ownerId) {
              /** team graph of mine */
              props.dataStore.networkFilters.team_file_imports = [];
            } else {
              /** team graph of others */
              props.dataStore.networkFilters.file_imports = [];

              props.dataStore.networkFilters.team_file_imports = allTeamFiles
                .filter((file) => file.owner_id === item[0])
                .reduce((acc, curr) => {
                  return [...acc, curr.id[0]];
                }, []);
            }
          }
          break;
        case 'trigger_by_count': {
          const filterKey = 'latest_mim_event_type';
          if (
            props.dataStore.networkFilters[filterKey] &&
            props.dataStore.networkFilters[filterKey].some(
              (obj) => obj.value === item,
            )
          )
            break;

          props.dataStore.networkFilters[filterKey].push(...getMimEvent(item));

          break;
        }
        case 'top_wealth_estimate': {
          const filterKeyWealth = 'wealth_estimate';

          if (
            props.dataStore.networkFilters[filterKeyWealth] &&
            props.dataStore.networkFilters[filterKeyWealth].some(
              (obj) => obj.label === item,
            )
          )
            break;

          const wealthArray = [];
          if (wealthScoreRanges && wealthScoreRanges.length > 0) {
            wealthScoreRanges.map((wealthScore) => {
              if (wealthScore.label.toLowerCase() === item.toLowerCase())
                wealthArray.push({
                  value: wealthScore.value,
                  label: wealthScore.label,
                  min: wealthScore.min,
                  max: wealthScore.max,
                });
            });
          }
          props.dataStore.networkFilters[filterKeyWealth].push(...wealthArray);
          break;
        }
        case 'client_inv_org_id':
          props.dataStore.groupByValues.org_name = [item];
          props.dataStore.networkFilters.filter_type = 'all';
          // props.dataStore.networkFilters.latest_mim_event_type = props.dataStore.lookUpLists.eventList

          break;
        case 'client_inv_trigger':
          props.dataStore.groupByValues.latest_mim_event_type =
            getMultipleValueSelected(props.dataStore.lookUpLists.eventList, [
              item,
            ]);
          props.dataStore.networkFilters.filter_type = 'all';

          break;
        case 'triggers_overview':
          props.dataStore.networkFilters.latest_mim_event_type =
            getMultipleValueSelected(props.dataStore.lookUpLists.eventList, [
              item,
            ]);
          props.dataStore.networkFilters.m2_date = m2_date;
          break;

        case 'triggers_view_box':
          props.dataStore.networkFilters.latest_mim_event_type =
            props.dataStore.lookUpLists.eventList;
          props.dataStore.networkFilters.m2_date = m2_date;
          break;
        default:
          break;
      }
      fetchListData();
    };

    const saveUserFilter = (
      filters,
      label,
      isActive,
      email_alert,
      separate_email_alert,
      filterType,
      fetchData,
    ) => {
      if (filterType) props.dataStore.networkFilters.filter_type = filterType;

      if (filterType === 'all') {
        props.dataStore.networkFilters.import_source = [];
        props.dataStore.networkFilters.file_imports = [];
        props.dataStore.networkFilters.team_file_imports = [];
      } else {
        props.dataStore.networkFilters.import_source =
          props.dataStore.lookUpLists.import_source;
        props.dataStore.networkFilters.file_imports =
          props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((item) => {
            if (item.value) return item.value;
          });
        const teamLookupToChoose = getTeamFileLookup(
          props.dataStore.networkFilters.filter_type,
          props.dataStore.lookUpLists.addImportsToTeam,
          props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
        );

        props.dataStore.networkFilters.team_file_imports =
          teamLookupToChoose.map((item) => {
            if (item.value) return item.value;
          });
      }
      props.dataStore.importSourceKeys.isImportSourceSaved = false;
      props.dataStore.importSourceKeys.isImportSourceChecked = false;

      const dataStoreFilters = copyUserFilter(
        filters,
        props.dataStore.networkFilters,
        props.dataStore.lookUpLists,
        label,
        isActive,
        email_alert,
        separate_email_alert,
        false,
      );

      props.dataStore.networkFilters = cloneDeep(dataStoreFilters);

      /** For handling redirections specially on the keys import_source,
       * file_imports and team_file_imports. Extra conditions added in addition to the common method */
      if (filters.import_source) {
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.file_imports) {
        props.dataStore.networkFilters.filterFileImports = filters.file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.file_imports = filters.file_imports;
        } else {
          const files = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          props.dataStore.lookUpLists.fileImportsWithoutEnrich &&
            props.dataStore.networkFilters.filterFileImports &&
            props.dataStore.lookUpLists.fileImportsWithoutEnrich.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (filters.team_file_imports) {
        props.dataStore.networkFilters.filterTeamFileImports =
          filters.team_file_imports;
        if (filterType === 'all') {
          props.dataStore.networkFilters.team_file_imports =
            filters.team_file_imports;
        } else {
          const files = [];
          const teamLookupToChoose = getTeamFileLookup(
            props.dataStore.networkFilters.filter_type,
            props.dataStore.lookUpLists.addImportsToTeam,
            props.dataStore.lookUpLists.teamFileImportsWithoutEnrich,
          );
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          teamLookupToChoose &&
            props.dataStore.networkFilters.filterTeamFileImports &&
            teamLookupToChoose.map((file) => {
              if (
                file &&
                file.value &&
                props.dataStore.networkFilters.filterTeamFileImports.includes(
                  file.value,
                )
              )
                files.push(file.value);
            });
          props.dataStore.networkFilters.team_file_imports = files;
        }
        props.dataStore.importSourceKeys.isImportSourceSaved = true;
      }

      if (!fetchData) props.dataStore.networkFilters.fetchData = true;
    };

    const handleViewAllClick = () => {
      if (!props.dataStore.lookUpLists.isPeoplePage)
        props.navigate('/prospect-finder/people');
      props.dataStore.networkFilters.fetchData = true;
    };

    const handleDeleteData = (b2bId, b2cId, length) => {
      const tableDataResult = networkListData.filter((item) => {
        if (!b2bId.includes(item.b2b_id) && !b2cId.includes(item.b2c_id))
          return item;
        setNetworkListDataCount(networkListDataCount - length);
      });
      setNetworkListData(tableDataResult);
    };

    const titleCaps = (title) => {
      const abbreviations = [
        'Avp',
        'Cdo',
        'Ceo',
        'Cfo',
        'Cio',
        'Cmo',
        'Coo',
        'Cro',
        'Cso',
        'Cto',
        'Cxo',
        'Dvp',
        'Evp',
        'Md',
        'Vp',
        'Svp',
      ];
      if (title) {
        abbreviations.map((abbre) => {
          if (title.includes(abbre)) {
            title = title.replace(abbre, abbre.toUpperCase());
          }
        });
      }
      return title;
    };

    const handleExportCsvClick = (
      selectedRowKeys,
      from,
      recordb2b,
      recordb2c,
    ) => {
      if (!crmValue) {
        props.navigate('/connect/export');
        return;
      }

      setisCrmCredentials({
        id1: recordb2b || '',
        id2: recordb2c || '',
      });
    };

    const showHelpModal = (e) => {
      e.stopPropagation();
      props.modalDataStore.eventHelpModalVisible = true;
    };

    const columns = [
      {
        title: 'Name',
        width: 150,
        key: 'full_name',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (_val, rec, index) => {
          if (rec.management_changes && rec.management_changes.length > 0) {
            rowKeyArray.push(`${rec.b2b_id}${rec.b2c_id}`);
          }
          return (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '85%',
                  wordBreak: 'break-word',
                  color: 'var(--color-primary)',
                }}
              >
                <BlurredContent blurred={blurContent}>
                  {namecase(rec.full_name)}
                </BlurredContent>
              </div>

              <RowExtra
                rec={rec}
                handleExportCsvClick={handleExportCsvClick}
                selectedProfileIds={selectedProfileIds}
                handleExportFromRowClick={(n2Ids) => setExportFromRow(n2Ids)}
              />
            </div>
          );
        },
      },
      {
        title: 'Title',
        width: 180,
        key: 'title',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                textTransform: 'capitalize',
              }}
            >
              <BlurredContent blurred={blurContent}>
                {titleCaps(val.title) || ''}
              </BlurredContent>
            </div>
          );
        },
      },
      {
        title: 'Company',
        key: 'org_name',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 180,
        render: (val) => {
          return (
            <div
              className={
                val.ai_org_id ? 'company-link' : 'company-link-disabled'
              }
            >
              <span
                onClick={(e) => {
                  if (blurContent) return;
                  if (!val || !val.ai_org_id) return;
                  e.stopPropagation();
                  showCompanyProfile(val.ai_org_id, val.org_name);
                }}
              >
                <BlurredContent blurred={blurContent}>
                  {val.org_name || ''}
                </BlurredContent>
              </span>
            </div>
          );
        },
      },

      {
        title: 'Degree',
        width: 110,
        align: 'center',
        render: (rec) => {
          if (rec.degree && rec.degree === 1) {
            return (
              <span>
                1<sup>st</sup>
              </span>
            );
          }
          if (rec.degree && rec.degree === 2) {
            return (
              <span>
                2<sup>nd</sup>
              </span>
            );
          }
          return '';
        },
      },
      {
        title: 'Mutual',
        width: 120,
        align: 'center',
        className: 'mutual-rel-col',
        render: (rec) => {
          return (
            <>
              {rec.mutual_contacts_details &&
                rec.mutual_contacts_details.length > 0 && (
                  <div>
                    <div>{rec.mutual_contacts_details.length}</div>
                    <div
                      className="popupDiv-mutual"
                      style={{ display: 'none', position: 'relative' }}
                    >
                      <div className="popup-mutual">
                        <div className="popupInner-mutual">
                          {!blurContent && (
                            <Tooltip>
                              <div>
                                {rec.mutual_contacts_details.map((obj) => {
                                  return (
                                    <div
                                      className="link"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        showPersonProfile(
                                          obj.b2b_id,
                                          obj.b2c_id,
                                        );
                                      }}
                                    >
                                      {obj.name}
                                    </div>
                                  );
                                })}
                              </div>
                            </Tooltip>
                          )}
                        </div>
                        <div className="popArrow-mutual" />
                      </div>
                    </div>
                  </div>
                )}
            </>
          );
        },
      },
      {
        title: 'City',
        key: 'location_city',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        width: 150,
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                textTransform: 'capitalize',
              }}
            >
              {val.location_city ? val.location_city.toLowerCase() : ''}
            </div>
          );
        },
      },
      {
        title: 'State',
        width: 100,
        key: 'location_state',
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val.location_state}
            </div>
          );
        },
      },
    ];
    if (props.dataStore.summaryFilter.triggers_view) {
      columns.splice(3, 0, {
        title: (
          <div style={{ position: 'relative', display: 'flex' }}>
            <div className="help-icon-event" onClick={showHelpModal}>
              <HelpIcon height="14px" width="14px" />
            </div>
            <div
              style={{
                marginLeft: '5px',
                color: 'var(--color-primary)',
                fontWeight: 'bold',
              }}
            >
              Trigger
            </div>
          </div>
        ),
        dataIndex: 'event',
        key: 'event',
        width: 180,
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val && val.event_type
                ? formatEventName(val.event_type)
                : ''}{' '}
            </div>
          );
        },
      });
    }

    const paginationProps = {
      ...listPagination,
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['20', '50', '100', '200'],
      total: listPagination.total,
      pageSize: listPagination.size,
      current: listPagination.currentPage,
    };

    const handleTableChange = (pagination, filtersArg, sorter) => {
      if (sorter && sorter.order) {
        sortInfo.column = sorter.columnKey;
        sortInfo.order = sorter.order === 'ascend' ? 'asc' : 'desc';
      } else {
        sortInfo.column = '';
        sortInfo.order = '';
      }

      if (
        pagination.pageSize === listPagination.pageSize &&
        pagination.current === listPagination.current
      ) {
        fetchListData();
      }
      setListPagination({
        ...pagination,
        pageSize: pagination.pageSize,
        current: pagination.current,
      });
    };

    const ManagementDescription = (record) => {
      let manage;
      if (record.management_changes && record.management_changes.length > 0) {
        manage = record.management_changes.map((change) => {
          const previousCompany = isLowerCase(change.previous_org_name)
            ? titleCase(change.previous_org_name)
            : change.previous_org_name;
          const currentCompany = isLowerCase(change.current_org_name)
            ? titleCase(change.current_org_name)
            : change.current_org_name;
          const previousTitle = isLowerCase(change.previous_title)
            ? titleCase(change.previous_title)
            : change.previous_title;
          const currentTitle = isLowerCase(change.current_title)
            ? titleCase(change.current_title)
            : change.current_title;
          if (change.management_change_type === 'Company Change') {
            if (!currentCompany && !previousCompany) {
              return <span>Changed Company</span>;
            }
            if (!currentCompany)
              return <span>{`Changed from ${previousCompany}`}</span>;

            if (!previousCompany || isEqual(previousCompany, currentCompany))
              return <span>{`Changed to ${currentCompany}`}</span>;

            if (currentCompany && previousCompany) {
              return (
                <span>{`Changed from ${previousCompany} to ${currentCompany}`}</span>
              );
            }
          } else {
            if (!currentTitle && !previousTitle) {
              return <span>Promoted</span>;
            }
            if (!currentTitle)
              return <span>{`Promoted from ${previousTitle}`}</span>;

            if (!previousTitle || isEqual(previousTitle, currentTitle))
              return <span>{`Promoted to ${currentTitle}`}</span>;

            if (currentTitle && previousTitle) {
              return (
                <span>{`Promoted from ${previousTitle} to ${currentTitle}`}</span>
              );
            }
          }
        });
        return (
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            {manage}
          </span>
        );
      }
    };

    const onModalCancel = () => {
      /**  @dropdownSelectedId is updated when clicked from Dashboard-Opportunities,
       * this variable is kept to avoid the issue of overriding the selectedid of dropdown
       * with the opportunity clicked item, when closing the peopleviewmodal
       */
      const selectedIdValue =
        props.dataStore.summaryFilter.dropdownSelectedId ||
        props.dataStore.summaryFilter.selectedId;
      props.dataStore.summaryFilter = {};
      props.dataStore.resetNetworkFilters();
      props.dataStore.networkFilters.selectedIdValue = selectedIdValue;
      onCancel();
    };

    const getRowKey = (rec) => {
      if (rec.event && rec.event.event_id)
        return `${rec.b2b_id}${rec.b2c_id}${rec.event.event_id}`;
      return `${rec.b2b_id}${rec.b2c_id}`;
    };

    const setProgress = (value) => {
      setIsLoading(value);
    };

    return (
      <div className="people-view-modal">
        <Modal
          width="100%"
          open
          wrapClassName="peopleview-modal-class"
          footer={null}
          onCancel={onModalCancel}
        >
          <div className="loader-wrapper">
            {isLoading && (
              <LoaderAnimation loaderId="people-modal-loader" size="medium" />
            )}
            <div className={isLoading ? 'aid-div-disable' : 'aid-div-enable'}>
              <div className="top-section">
                <>{splitNumberWithCommas(networkListDataCount)} Results</>
                {/* {!props.dataStore.summaryFilter.triggers_view
                                    ?<>{splitNumberWithCommas(networkListDataCount)} Results</>
                                    :<div></div>
                                } */}
                {networkListDataCount > 0 &&
                  !props.dataStore.lookUpLists.isPeoplePage && (
                    <div
                      className="people-view-click"
                      onClick={() => handleViewAllClick()}
                    >
                      Click to Review Results in People View
                    </div>
                  )}
              </div>
              <ExportTable
                dataCount={networkListDataCount}
                changeSelectedProfileIds={(ids) => setSelectedProfileIds(ids)}
                columns={columns}
                data={networkListData}
                fromProfile
                onChange={handleTableChange}
                rowKey={(rec) => getRowKey(rec)}
                pagination={paginationProps}
                pageSize={listPagination.pageSize}
                deleteData={handleDeleteData}
                crmRowCredentials={isCrmCredentials}
                setProgress={setProgress}
                clearCrmCredentials={() => {
                  setisCrmCredentials(null);
                }}
                expandable={{
                  expandedRowRender: (rec) => (
                    <p
                      style={{
                        margin: 0,
                        paddingLeft: 17,
                      }}
                    >
                      {ManagementDescription(rec)}
                    </p>
                  ),
                }}
                rowKeyArray={rowKeyArray}
                exportFromRow={exportFromRow}
                clearExport={() => {
                  setExportFromRow(null);
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }),
);

export default withRouter(PeopleViewModal);
