// import axios from "axios";
import { requestWithAuth } from './api';

// requestWithAuth: url: any, method: any, params: any, body: any, cancelToken: any, progressId: any

export const profileService = {
  purchaseProfile: (aidentifed_id, cancelTokenSource) => {
    return purchasePremiumProfile(aidentifed_id, cancelTokenSource, false);
  },
  purchasePremiumProfile: (
    aidentifed_id,
    cancelTokenSource,
    premium = true,
  ) => {
    if (!aidentifed_id) {
      throw new Error('aidentified_id is required');
    }

    return requestWithAuth(
      '/v0/purchase-profile',
      'POST',
      null,
      { aidentifed_id: aidentifed_id, premium: premium },
      cancelTokenSource?.token,
    );
  },
};
