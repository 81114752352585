import Avatar1 from '../assets/avatars/ai_avtr_01.svg?react';
import Avatar2 from '../assets/avatars/ai_avtr_02.svg?react';
import Avatar3 from '../assets/avatars/ai_avtr_03.svg?react';
import Avatar4 from '../assets/avatars/ai_avtr_04.svg?react';
import Avatar5 from '../assets/avatars/ai_avtr_05.svg?react';
import Avatar6 from '../assets/avatars/ai_avtr_06.svg?react';
import Avatar7 from '../assets/avatars/ai_avtr_07.svg?react';
import Avatar8 from '../assets/avatars/ai_avtr_08.svg?react';

export const propertyTypes = [
  {
    label: 'Residential',
    value: 'Residential',
  },
  {
    label: 'Commercial',
    value: 'Commercial',
  },
  {
    label: 'Agriculture / Farming',
    value: 'Agriculture / Farming',
  },
  {
    label: 'Vacant Land',
    value: 'Vacant Land',
  },
  {
    label: 'Public Works',
    value: 'Public Works',
  },
  {
    label: 'Other / Unknown',
    value: 'Other / Unknown',
  },
];

export const propertyValues = [
  {
    // label: '$0 - $250,000',
    label: '$0 to $250K',
    value: '0 - 250,000',
    valueObject: { gte: 0, lte: 250000 },
  },
  {
    label: '$250K to $500K',
    value: '250,000 - 500,000',
    valueObject: { gte: 250000, lte: 500000 },
  },
  {
    label: '$500K to $750K',
    value: '500,000 - 750,000',
    valueObject: { gte: 500000, lte: 750000 },
  },
  {
    label: '$750K to $1M',
    value: '750,000 - 1,000,000',
    valueObject: { gte: 750000, lte: 1000000 },
  },
  {
    label: '$1M to $3M',
    value: '1,000,000 - 3,000,000',
    valueObject: { gte: 1000000, lte: 3000000 },
  },
  {
    label: '$3M to $5M',
    value: '3,000,000 - 5,000,000',
    valueObject: { gte: 3000000, lte: 5000000 },
  },
  {
    label: '$5M to $8M',
    value: '5,000,000 - 8,000,000',
    valueObject: { gte: 5000000, lte: 8000000 },
  },
  {
    label: '$8M to $10M',
    value: '8,000,000 - 10,000,000',
    valueObject: { gte: 8000000, lte: 10000000 },
  },
  {
    label: '$10M+',
    value: '10,000,000+',
    valueObject: { gte: 10000001, lte: null },
  },
];

export const relationshipIntelligence = [
  {
    path: '/relationship-intelligence/dashboard',
    name: 'Dashboard',
    id: 'dashboard',
  },
  {
    path: '/relationship-intelligence/opportunities',
    name: 'Opportunities',
    id: 'opportunities',
  },
  {
    path: '/relationship-intelligence/super-connectors',
    name: 'Super Connectors',
    id: 'super-connector',
  },
  // {
  //   path: '/relationship-intelligence/triggers',
  //   name: 'Triggers',
  // },
  // { path: '/relationship-intelligence/insights', name: 'Insights' },
];

export const ModeledChildPresenceValues = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const salaryFilter = [
  {
    text: '$0 to $100K',
    label: '$0 to $100K',
    value: '$0 - $100k',
    min: 0,
    max: 100000,
  },
  {
    text: '$101K to $250K',
    label: '$101K to $250K',
    value: '$101k - $250k',
    min: 100001,
    max: 250000,
  },
  {
    text: '$251K to $500K',
    label: '$251K to $500K',
    value: '$251k - $500k',
    min: 250001,
    max: 500000,
  },
  {
    text: '$501K to $1M',
    label: '$501K to $1M',
    value: '$501k - $1M',
    min: 500001,
    max: 1000000,
  },
  {
    text: '$1M+',
    label: '$1M+',
    value: '$1M+',
    min: 1000001,
    max: null,
  },
];

export const gender = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
];

export const companyType = [
  {
    label: 'Public',
    value: 'public',
  },
  {
    label: 'Private',
    value: 'private',
  },
  {
    label: 'All',
    value: 'all',
  },
];

export const groupByText = {
  age_range: 'Age Segment(s)',
  org_name: 'Company(s)',
  employee_count: 'Company Headcount Segment(s)',
  stock_performance: 'Company Viability Segment(s)',
  title_role: 'Function(s)',
  funding_round: 'Funding Round Type(s)',
  location_city: 'Home City(s)',
  location_state: 'Home State(s)',
  home_postal_code: 'Home Zip(s)',
  industry: 'Industry(s)',
  inferred_salary: 'Inferred Income Segment(s)',
  interests: 'Modeled Interest(s)',
  marital_status: 'Modeled Marital Status Segment(s)',
  number_of_owned_properties: 'Multiple Property Segment(s)',
  'previous_experience.org_name': 'Previous Company(s)',
  property_type: 'Property Type Segment(s)',
  remaining_share_value_sum: 'Remaining Share Balance Segment(s)',
  schools: 'School(s)',
  gender: 'Sex Segment(s)',
  tags_column: 'Tag(s)',
  top_team_members: 'Team Relationship Path(s)',
  title: 'Title(s)',
  title_rank: 'Title Group(s)',
  credentials: 'Credential(s)',
  latest_mim_event_type: 'Trigger Type(s)',
  wealth_estimate: 'Wealth Segment(s)',
  mapped_connection_count: '# of Mapped Relationship(s)',
};

export const groupByFilter = [
  {
    label: 'Select Option',
    value: 'select',
  },
  {
    label: 'Age',
    value: 'age_range',
  },
  {
    label: 'Companies',
    value: 'org_name',
  },
  {
    label: 'Company Headcount',
    value: 'employee_count',
  },
  {
    label: 'Company Viability',
    value: 'stock_performance',
  },
  {
    label: 'Credentials',
    value: 'credentials',
  },
  {
    label: 'Function',
    value: 'title_role',
  },
  {
    label: 'Funding Round',
    value: 'funding_round',
  },
  {
    label: 'Home City',
    value: 'location_city',
  },
  {
    label: 'Home State',
    value: 'location_state',
  },
  {
    label: 'Home Zip',
    value: 'home_postal_code',
  },
  {
    label: 'Industries',
    value: 'industry',
  },
  {
    label: 'Inferred Income',
    value: 'inferred_salary',
  },
  {
    label: 'Modeled Interests',
    value: 'interests',
  },
  {
    label: 'Modeled Marital Status',
    value: 'marital_status',
  },
  {
    label: 'Multiple Properties',
    value: 'number_of_owned_properties',
  },
  {
    label: 'Previous Companies',
    value: 'previous_experience.org_name',
  },
  {
    label: 'Property Type',
    value: 'property_type',
  },
  {
    label: 'Remaining Share Balance',
    value: 'remaining_share_value_sum',
  },
  {
    label: 'Schools',
    value: 'schools',
  },

  {
    label: 'Sex',
    value: 'gender',
  },
  {
    label: 'Tags',
    value: 'tags_column',
  },
  {
    label: 'Team Relationship Paths',
    value: 'top_team_members',
  },
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Title Group',
    value: 'title_rank',
  },
  {
    label: 'Triggers',
    value: 'latest_mim_event_type',
  },

  {
    label: 'Wealth Segment',
    value: 'wealth_estimate',
  },
  {
    label: '# of Mapped Relationships',
    value: 'mapped_connection_count',
  },
];

export const maritalStatus = [
  {
    label: 'Married',
    value: 'M',
  },
  {
    label: 'Single',
    value: 'S',
  },
];

export const interests = [
  { label: 'Barbecuing', value: 'barbecuing' },
  { label: 'Baseball', value: 'baseball' },
  { label: 'Basketball', value: 'basketball' },
  { label: 'Bike - Mountain/Road', value: 'bike_mountain_road' },
  { label: 'Bird Watching', value: 'bird_watching' },
  { label: 'Board Games', value: 'board_games' },
  { label: 'Boating/Sailing', value: 'boating_or_sailing' },
  { label: 'Camping/Hiking', value: 'camping_or_hiking' },
  { label: 'Canoeing/Kayaking', value: 'canoeing_kayaking' },
  { label: 'Card Games', value: 'card_games' },
  { label: 'Charity: Animals', value: 'contribute_animal' },
  {
    label: 'Charity: Art/Culture/Humanities',
    value: 'charity_art_culture_humanities',
  },
  { label: 'Charity: Children', value: 'contribute_childrens' },
  { label: 'Charity: Education', value: 'charity_education' },
  { label: 'Charity: Environment', value: 'contribute_environm' },
  { label: 'Charity: Health', value: 'contribute_health' },
  {
    label: 'Charity: Politics - Conservative',
    value: 'contribute_political_conservative',
  },
  {
    label: 'Charity: Politics - Liberal',
    value: 'contribute_political_liberal',
  },
  { label: 'Charity: Religion', value: 'contribute_religious' },
  {
    label: 'Charity: Social Services/Welfare',
    value: 'charity_social_services_welfare',
  },
  { label: 'Comedy Clubs', value: 'comedy_clubs' },
  { label: 'Concert: Classical', value: 'concert_classical' },
  { label: 'Concert: Country', value: 'concert_country' },
  {
    label: 'Concert: R&B/Hip-Hop/Rap',
    value: 'concert_rb_hip_hop_rap',
  },
  { label: 'Concert: Rock/Pop', value: 'concert_rock_pop' },
  { label: 'Concerts', value: 'concerts' },
  { label: 'Cooking for Fun', value: 'cooking_for_fun' },
  { label: 'Cruise Ship Vacations', value: 'cruise_ship_vacations' },
  { label: 'Dance Performances', value: 'dance_performances' },
  {
    label: 'Dining Out - Not Fast Food',
    value: 'dining_out_not_fast_food',
  },
  { label: 'Education Courses', value: 'education_courses' },
  { label: 'Fishing', value: 'fishing' },
  { label: 'Fitness', value: 'fitness' },
  { label: 'Fly Fishing', value: 'fly_fishing' },
  { label: 'Football', value: 'football' },
  { label: 'Fresh Water Fish', value: 'fresh_water_fish' },
  { label: 'Gardening', value: 'gardening' },
  { label: 'Go to a Beach/Lake', value: 'go_to_a_beach_lake' },
  { label: 'Golf', value: 'golf' },
  { label: 'Gourmet Cooking', value: 'hobby_cooking_gourmet' },
  { label: 'Hockey', value: 'hockey' },
  { label: 'Home Improvement', value: 'home_improvement' },
  { label: 'Horseback Riding', value: 'horseback_riding' },
  { label: 'Hunting', value: 'hunting' },
  { label: 'Ice Skating', value: 'ice_skating' },
  {
    label: 'Investments: Mutual Funds/CDs',
    value: 'investments_own_mutual_funds_cds',
  },
  {
    label: 'Investments: Stocks/Bonds',
    value: 'investments_own_stocks_or_bonds',
  },
  { label: 'Listening to Music', value: 'listening_to_music' },
  { label: 'Live Theater', value: 'live_theater' },
  { label: 'Motorcycles', value: 'motor_cycle' },
  { label: 'Music Festivals', value: 'music_festivals' },
  { label: 'Music: 1940s-1950s Pop', value: 'music_1940s_1950s_pop' },
  {
    label: 'Music: 60s-70s Pop Classic Rock',
    value: 'music_60s_70s_pop_classic_rock',
  },
  { label: 'Music: 80s Old School', value: 'music_80s_old_school' },
  { label: 'Music: 80s Pop/Rock', value: 'music_80s_pop_rock' },
  {
    label: 'Music: Adult Contemporary Pop',
    value: 'music_adult_contemporary_pop',
  },
  { label: 'Music: Alternative', value: 'music_alternative' },
  { label: 'Music: Bluegrass', value: 'music_bluegrass' },
  {
    label: 'Music: Broadway/Movie/TV Soundtracks',
    value: 'music_broadway_movie_tv_soundtracks',
  },
  { label: 'Music: Classical', value: 'music_classical' },
  {
    label: 'Music: Early 90s Hip Hop',
    value: 'music_early_90s_hip_hop',
  },
  { label: 'Music: Easy Listening', value: 'music_easy_listening' },
  { label: 'Music: Hard Rock', value: 'music_hard_rock' },
  {
    label: 'Music: Heavy/Speed Metal',
    value: 'music_heavy_speed_metal',
  },
  { label: 'Music: Hip Hop', value: 'music_hip_hop' },
  { label: 'Music: Jazz', value: 'music_jazz' },
  {
    label: 'Music: Mainstream Country',
    value: 'music_mainstream_country',
  },
  {
    label: 'Music: Modern Adult Contemporary',
    value: 'music_modern_adult_contemporary',
  },
  { label: 'Music: New Age', value: 'music_new_age' },
  { label: 'Music: Pop Alternative', value: 'music_pop_alternative' },
  { label: 'Music: Pop Hip Hop', value: 'music_pop_hip_hop' },
  { label: 'Music: Pop R&B Tempo', value: 'music_pop_rb_tempo' },
  { label: 'Music: Pure Pop', value: 'music_pure_pop' },
  { label: 'Music: Reggae', value: 'music_reggae' },
  { label: 'Music: Salsa/Merengue', value: 'music_salsa_merengue' },
  { label: 'Music: Techno', value: 'music_techno' },
  {
    label: 'Music: Traditional Country',
    value: 'music_traditional_country',
  },
  {
    label: 'Music: Traditional Soul/R&B',
    value: 'music_traditional_soul_rb',
  },
  { label: 'Nascar', value: 'nascar' },
  { label: 'Needlework/Quilting', value: 'needlework_quilting' },
  {
    label: 'Painting, Drawing, Sculpting',
    value: 'painting_drawing_sculpting',
  },
  { label: 'Pets', value: 'pets' },
  {
    label: 'Photography/Videography',
    value: 'hobby_photography_videography',
  },
  {
    label: 'Play Musical Instrument',
    value: 'play_musical_instrument',
  },
  { label: 'Racquetball', value: 'racquetball' },
  { label: 'Reading Books', value: 'reading_books' },
  { label: 'Running', value: 'running' },
  { label: 'Snow Skiing', value: 'snow_skiing' },
  { label: 'Snowboarding', value: 'snowboarding' },
  { label: 'Snowmobiling', value: 'snowmobiling' },
  { label: 'Soccer', value: 'soccer' },
  { label: 'Softball', value: 'softball' },
  { label: 'Sports', value: 'sports' },
  { label: 'Tennis', value: 'tennis' },
  { label: 'Travel', value: 'travel' },
  { label: 'Unique Cars', value: 'cars' },
  { label: 'Volleyball', value: 'volleyball' },
  { label: 'Wine Appreciation', value: 'hobby_wine_appreciation' },
  { label: 'Yoga', value: 'yoga' },
];

export const fundingRoundList = [
  { label: 'Angel Round', value: 'Angel Round' },
  { label: 'Grant', value: 'Grant' },
  { label: 'Pre Seed Round', value: 'Pre Seed Round' },
  { label: 'Seed Round', value: 'Seed Round' },
  { label: 'Convertible Note', value: 'Convertible Note' },
  { label: 'Equity Crowdfunding', value: 'Equity Crowdfunding' },
  { label: 'Series A', value: 'Series A' },
  { label: 'Series B', value: 'Series B' },
  { label: 'Series C', value: 'Series C' },
  { label: 'Series D', value: 'Series D' },
  { label: 'Series E', value: 'Series E' },
  { label: 'Series F', value: 'Series F' },
  { label: 'Series G', value: 'Series G' },
  { label: 'Series H', value: 'Series H' },
  { label: 'Series I', value: 'Series I' },
  { label: 'Series J', value: 'Series J' },
  { label: 'Venture Round', value: 'Venture Round' },
  { label: 'Corporate Round', value: 'Corporate Round' },
  { label: 'Funding Round', value: 'Funding Round' },
  { label: 'Private Equity Round', value: 'Private Equity Round' },
  { label: 'Debt Financing', value: 'Debt Financing' },
  { label: 'Post-IPO Equity', value: 'Post-IPO Equity' },
  { label: 'Post-IPO Secondary', value: 'Post-IPO Secondary' },
  { label: 'Post-IPO Debt', value: 'Post-IPO Debt' },
  { label: 'Secondary Market', value: 'Secondary Market' },
  { label: 'Initial Coin Offering', value: 'Initial Coin Offering' },
];

export const mappedConnectionsCount = [
  {
    label: '1 to 25',
    value: '1-25',
    valueObject: { gte: 1, lte: 25 },
  },
  {
    label: '26 to 50',
    value: '26-50',
    valueObject: { gte: 26, lte: 50 },
  },
  {
    label: '51 to 100',
    value: '51-100',
    valueObject: { gte: 51, lte: 100 },
  },
  {
    label: '101 to 200',
    value: '101-200',
    valueObject: { gte: 101, lte: 200 },
  },
  {
    label: '200+',
    value: '200+',
    valueObject: { gte: 200, lte: null },
  },
];

export const companySizeFilter = [
  {
    label: '1 to 10',
    value: '1-10',
  },
  {
    label: '11 to 50',
    value: '11-50',
  },
  {
    label: '51 to 100',
    value: '51-100',
  },
  {
    label: '101 to 250',
    value: '101-250',
  },
  {
    label: '251 to 500',
    value: '251-500',
  },
  {
    label: '501 to 1,000',
    value: '501-1000',
  },
  {
    label: '1,001 to 5,000',
    value: '1001-5000',
  },
  {
    label: '5,001 to 10,000',
    value: '5001-10000',
  },
  {
    label: '10,000+',
    value: '10000+',
  },
];

export const companyViablityRanges = [
  {
    label: 'Very High',
    value: '1.3',
  },
  {
    label: 'High',
    value: '1.1, 1.3',
  },
  {
    label: 'Neutral',
    value: '0.9, 1.1',
  },
  {
    label: 'Low',
    value: '0.7, 0.9',
  },
  {
    label: 'Very Low',
    value: '0, 0.7',
  },
];

export const wealthScoreRanges = [
  {
    text: '<$100K',
    label: '<$100K',
    value: 'Low Wealth Market',
    min: 0,
    max: 100000,
  },
  {
    text: '$100K+ to $500K',
    label: '$100K+ to $500K',
    value: 'Mass Market',
    min: 100001,
    max: 500000,
  },
  {
    text: '$500K+ to $1M',
    label: '$500K+ to $1M',
    value: 'Lower Mass Affluent Market',
    min: 500001,
    max: 1000000,
  },
  {
    text: '$1M+ to $2M',
    label: '$1M+ to $2M',
    value: 'Upper Mass Affluent Market',
    min: 1000001,
    max: 2000000,
  },
  {
    text: '$2M+ to $5M',
    label: '$2M+ to $5M',
    value: 'Middle Market',
    min: 2000001,
    max: 5000000,
  },
  {
    text: '$5M+ to $10M',
    label: '$5M+ to $10M',
    value: 'Affluent Market',
    min: 5000001,
    max: 10000000,
  },
  {
    text: '$10M+ to $20M',
    label: '$10M+ to $20M',
    value: 'Wealth Market',
    min: 10000001,
    max: 20000000,
  },
  {
    text: '$20M+ to $50M',
    label: '$20M+ to $50M',
    value: 'Lower Middle Wealth Market',
    min: 20000001,
    max: 50000000,
  },
  {
    text: '$50M+ to $100M',
    label: '$50M+ to $100M',
    value: 'Upper Middle Wealth Market',
    min: 50000001,
    max: 100000000,
  },
  {
    text: '$100M+ to $500M',
    label: '$100M+ to $500M',
    value: 'High Wealth Market',
    min: 100000001,
    max: 500000000,
  },
  {
    text: '$500M+ to $1B',
    label: '$500M+ to $1B',
    value: 'Ultra-High Wealth Market',
    min: 500000001,
    max: 1000000000,
  },
  {
    text: '>$1B',
    label: '>$1B',
    value: 'Billionaire Wealth Market',
    min: 1000000001,
    max: null,
  },
];

export const titleRankList = [
  { value: 24, label: 'C-Suite' },
  { value: 10, label: 'CEO' },
  { value: 11, label: 'Co-CEO' },
  { value: 20, label: 'COO' },
  { value: 21, label: 'CFO' },
  { value: 22, label: 'CIO' },
  { value: 23, label: 'CTO' },
  { value: 25, label: 'CMO' },
  { value: 30, label: 'Principal/Owner' },
  { value: 40, label: 'Founder' },
  { value: 45, label: '(Co)Chairman' },
  { value: 46, label: 'Vice Chairman' },
  { value: 47, label: 'Board Member' },
  { value: 48, label: 'Advisory Board Member' },
  { value: 50, label: 'Managing Partner' },
  { value: 51, label: 'Partner' },
  { value: 52, label: 'Managing Director' },
  { value: 53, label: 'Managing Member' },
  { value: 80, label: 'President' },
  { value: 90, label: 'Executive Vice President' },
  { value: 100, label: 'Senior Vice President' },
  { value: 110, label: 'Vice President' },
  { value: 120, label: 'AVP' },
  { value: 121, label: 'Department Chief' },
  { value: 122, label: 'Department Head' },
  { value: 125, label: 'General Manager' },
  { value: 129, label: 'Executive Director' },
  { value: 130, label: 'Director (non-board)' },
  { value: 140, label: 'Manager' },
  { value: 150, label: 'Judge' },
  { value: 160, label: 'Attorney' },
  { value: 161, label: 'Medical Doctor' },
  { value: 165, label: 'Medical Assistant' },
  { value: 166, label: 'Nurse' },
  { value: 809, label: 'Senior Consultant' },
  { value: 810, label: 'Consultant' },
  { value: 820, label: 'Account Executive' },
  { value: 830, label: 'Financial Advisor' },
  { value: 840, label: 'Sales Representative' },
  { value: 850, label: 'Business Analyst' },
  { value: 900, label: 'Realtor' },
  { value: 930, label: 'Professor' },
  { value: 940, label: 'Accountant' },
  { value: 950, label: 'Software Engineer' },
  { value: 960, label: 'Engineer' },
  { value: 970, label: 'Recruiter' },
  { value: 990, label: 'Teacher' },
  { value: 995, label: 'Retired' },
  { value: 1000, label: 'Other' },
  { value: 1001, label: 'No title' },
];

export const importSourceList = [
  { label: 'LinkedIn', value: 'linkedin' },
  { label: 'iCloud', value: 'icloud' },
  { label: 'Google', value: 'google' },
  { label: 'Outlook', value: 'outlook' },
  { label: 'Aidentified Mapped Relationships', value: 'aidentified_auto' },
  { label: 'Added in Aidentified App', value: 'aidentified' },
];

export const importSource = [
  'linkedin',
  'icloud',
  'google',
  'outlook',
  'aidentified',
  'aidentified_auto',
];

export const filterLabelNames = {
  mapped_relationship_weights: 'Relationship Weights',
  mapped_relationship_types: 'Relationship Types',
  filter_type: 'Connection',
  full_name: 'Name',
  home_search: 'Home Location',
  home_address_suggest: 'Home Address',
  exc_home_address_suggest: 'Exclude Home Address',
  location_city: 'Cities',
  location_state: 'State',
  home_postal_code: 'Zip Code',
  age: 'Age',
  range_list: 'Age',
  gender: 'Sex',
  marital_status: 'Modeled Marital Status',
  presence_of_children: 'Modeled Presence of Children',
  interests: 'Modeled Interests',
  org_name: 'Companies',
  exc_current_experience: 'Exclude Companies',
  county: 'County',
  previous_experience_org_name: 'Previous Companies',
  current_org_years: 'Years of Experience at Current Employer',
  wealth_estimate_range: 'Wealth Segment Range',
  total_years_of_experience: 'Years of Career Experience',
  event_org_name: 'Trigger Companies',
  number_of_mapped_connections: '# of Mapped Relationships',
  relmap_org: 'Employee RelMap',
  industry: 'Industries',
  exc_industry: 'Exclude Industries',
  credentials: 'Credentials',
  title_rank: 'Title Group',
  title_role: 'Function',
  title: 'Title',
  exc_title_rank: 'Exclude Title Group',
  exc_title: 'Exclude Title',
  schools: 'Schools',
  company_search: 'Company Location',
  inferred_salary_range: 'Inferred Income',
  stock_performance: 'Company Viability',
  employee_count: 'Company Headcount',
  wealth_estimate: 'Wealth Segment',
  latest_mim_event_type: 'Triggers',
  latest_mim_monetary_value: 'Trigger Value',
  // latest_mim_monetary_value_range: 'Trigger Value',
  latest_mim_date: 'Trigger Timeframe',
  m2_date: 'Trigger Timeframe',
  period: 'Period',
  dateRange: 'Trigger Timeframe',
  team: 'Team',
  import_source: 'Import Source',
  file_imports: 'File Imports',
  team_file_imports: 'Shared File Imports',
  is_household_members_included: 'Household Member',
  is_work_emails_included: 'Email (Work)',
  is_personal_emails_included: 'Email (Personal)',
  is_mobile_phones_included: 'Phone (Mobile)',
  is_other_phones_included: 'Phone (Other)',
  is_facebook_included: 'Facebook',
  is_linkedin_included: 'Linkedin',
  is_twitter_included: 'Twitter',
  is_home_address_included: 'Home Address',
  // is_b2b_id_included: 'Professional',
  // is_b2c_id_included: 'Neustar ID',
  is_b2c_id_included: 'Household',
  is_age_verified: 'AI Inspected Age',
  is_home_address_verified: 'AI Inspected Home Address',
  is_home_ownership_verified: 'AI Inspected Home Owner Status',
  is_gender_verified: 'AI Inspected Sex',
  is_career_verified: 'AI Inspected Career',
  is_income_verified: 'AI Inspected Inferred Income',
  is_wealth_verified: 'AI Inspected Wealth Segment',
  li_only: 'Professional Plus',
  is_medical_included: 'Medical',
  is_notes_included: 'Notes',
  funding_round: 'Funding Round',
  // is_wealth_events: 'People with Wealth Events',
  quick_search: 'Quick Search',
  opportunities_event_name: 'Trigger',
  opportunities_person_name: 'Person Name',
  daily_opportunities_date: 'Daily Opportunities',
  company_type: 'Company Type',
  groupby_age: 'Group People by Age',
  groupby_schools: 'Group People by School',
  groupby_industry: 'Group People by Industry',
  groupby_org_name: 'Group People by Company',
  groupby_location_city: 'Group People by City',
  groupby_home_postal_code: 'Group People by Zip Code',
  groupby_employee_count: 'Group People by Company Headcount',
  groupby_location_state: 'Group People by State',
  groupby_stock_performance: 'Group People by Company Viability',
  groupby_top_team_members: 'Group People by Team Relationship Paths',
  groupby_title: 'Group People by Title',
  groupby: 'Group by',
  groupby_latest_mim_event_type: 'Group People by Triggers',
  groupby_marital_status: 'Group People by Modeled Marital Status',
  groupby_gender: 'Group People by Sex',
  groupby_title_role: 'Group People by Function',
  groupby_credentials: 'Group People by Credentials',
  groupby_title_rank: 'Group People by Title Group',
  groupby_inferred_salary_range: 'Group People by Inferred Income',
  groupby_wealth_estimate: 'Group People by Wealth Segment',
  groupby_interests: 'Group People by Modeled Interests',
  groupby_previous_experience_org_name: 'Group People by Previous Company',
  groupby_tags: 'Group People by Tags',
  groupby_funding_round: 'Group People by Funding Round',
  groupby_number_of_owned_properties: 'Group People by Multiple Properties',
  groupby_property_type: 'Group People by Property Type',
  groupby_mapped_connection_count: 'Group People by # of Mapped Relationships',
  is_super_connectors_applied: 'Super Connectors Applied',
  naics_codes: 'NAICS',
  property_type: 'Property Types',
  home_value_estimate: 'Property Value',
  home_purchase_date: 'Home Purchase Date',
  home_sale_date: 'Home Sale Date',
  norm_home_owner: 'Home Owner Status',
  // is_basement_included: 'Basement',
  is_elevator_included: 'Elevator',
  garage_cars: 'Garage Cars',
  number_of_owned_properties: 'Multiple Properties',
  is_pool_included: 'Pool',
  number_of_stories: 'Number of Stories',
  number_of_baths: 'Number of Baths',
  number_of_bedrooms: 'Number of Bedrooms',
  number_of_partial_baths: 'Number of Partial Baths',
  total_number_of_rooms: 'Total Number of Rooms',
  lotsize_square_feet: 'Lot Square Feet',
  number_of_units: 'Number of Units',
  effective_year_built: 'Effective Year Built',
  remaining_share_value_sum: 'Remaining Share Balance',
  groupby_remaining_share_value_sum: 'Group People by Remaining Share Balance',
  irs_tax_insights: 'US Income Tax Insights',
  map_query: 'Map Aggregations',
  tags_checked: 'Tags',
  shared_tags: 'Shared Tags',
  is_age_included: 'Age',
  is_norm_home_owner_included: 'Property Ownership',
  is_home_value_estimate_included: 'Property Value',
  is_inferred_salary_included: 'Inferred Income',
  is_geo_tax_investable_assets_included: 'Zip+4 Tax Info - Investable Assets',
  is_b2b_id_included: 'Career Information',
  is_remaining_share_value_sum_included: 'Remaining Share Balance(s)',
  has_wealth_triggers: 'Wealth Triggers',
};

export const homeOwnerStatusList = [
  { label: 'Home Owner', value: 'Home Owner' },
  { label: 'Renter', value: 'Renter' },
];
export const Properties2To5 = [
  {
    label: '2',
    value: 2,
    rangeObject: { gte: 2, lte: 2 },
  },
  {
    label: '3',
    value: 3,
    rangeObject: { gte: 3, lte: 3 },
  },
  {
    label: '4',
    value: 4,
    rangeObject: { gte: 4, lte: 4 },
  },
  {
    label: '5',
    value: 5,
    rangeObject: { gte: 5, lte: 5 },
  },
  {
    label: '5+',
    value: 6,
    rangeObject: { gte: 6, lte: null },
  },
];
export const Numbers1To10 = [
  {
    label: '1',
    value: 1,
    rangeObject: { gte: 1, lte: 1 },
  },
  {
    label: '2',
    value: 2,
    rangeObject: { gte: 2, lte: 2 },
  },
  {
    label: '3',
    value: 3,
    rangeObject: { gte: 3, lte: 3 },
  },
  {
    label: '4',
    value: 4,
    rangeObject: { gte: 4, lte: 4 },
  },
  {
    label: '5',
    value: 5,
    rangeObject: { gte: 5, lte: 5 },
  },
  {
    label: '6',
    value: 6,
    rangeObject: { gte: 6, lte: 6 },
  },
  {
    label: '7',
    value: 7,
    rangeObject: { gte: 7, lte: 7 },
  },
  {
    label: '8',
    value: 8,
    rangeObject: { gte: 8, lte: 8 },
  },
  {
    label: '9',
    value: 9,
    rangeObject: { gte: 9, lte: 9 },
  },
  {
    label: '10',
    value: 10,
    rangeObject: { gte: 10, lte: 10 },
  },
  {
    label: '10+',
    value: 11,
    rangeObject: { gte: 11, lte: null },
  },
];

export const credentialsLookup = [
  {
    label: "Bachelor's Degree",
    value: "Bachelor's Degree",
  },
  {
    label: "Master's Degree",
    value: "Master's Degree",
  },
  {
    label: 'Associate Degree',
    value: 'Associate Degree',
  },
  {
    label: 'PhD',
    value: 'PhD',
  },
  {
    label: 'MBA',
    value: 'MBA',
  },
  {
    label: 'JD',
    value: 'JD',
  },
  {
    label: 'MD',
    value: 'MD',
  },
  {
    label: 'PMP',
    value: 'PMP',
  },
  {
    label: 'CPA',
    value: 'CPA',
  },
  {
    label: 'PharmD',
    value: 'PharmD',
  },
  {
    label: 'CFA',
    value: 'CFA',
  },
  {
    label: 'CFP',
    value: 'CFP',
  },
  {
    label: 'DDS',
    value: 'DDS',
  },
  {
    label: 'DPT',
    value: 'DPT',
  },
  {
    label: 'DNP',
    value: 'DNP',
  },
  {
    label: 'DVM',
    value: 'DVM',
  },
  {
    label: 'DMD',
    value: 'DMD',
  },
  {
    label: 'OD',
    value: 'OD',
  },
  {
    label: 'Series 6',
    value: 'Series 6',
  },
  {
    label: 'Series 7',
    value: 'Series 7',
  },
  {
    label: 'Series 63',
    value: 'Series 63',
  },
  {
    label: 'Series 65',
    value: 'Series 65',
  },
  {
    label: 'Series 66',
    value: 'Series 66',
  },
];

export const garageLookup = [
  {
    label: 'Pole Building Garage',
    value: 4,
    rangeObject: { gte: '4', lte: '4' },
  },
  {
    label: 'Garage, Attached',
    value: 11,
    rangeObject: { gte: '11', lte: '11' },
  },
  {
    label: 'Garage, Detached',
    value: 12,
    rangeObject: { gte: '12', lte: '12' },
  },
  {
    label: 'Garage, Unfinished',
    value: 13,
    rangeObject: { gte: '13', lte: '13' },
  },
  {
    label: 'Garage, Finished',
    value: 14,
    rangeObject: { gte: '14', lte: '14' },
  },
  {
    label: 'Garage, Enclosed',
    value: 15,
    rangeObject: { gte: '15', lte: '15' },
  },
  {
    label: 'Garage, Open',
    value: 16,
    rangeObject: { gte: '16', lte: '16' },
  },
  {
    label: 'Detached (Unspecified)',
    value: 17,
    rangeObject: { gte: '17', lte: '17' },
  },
  {
    label: 'Attached (Unspecified)',
    value: 18,
    rangeObject: { gte: '18', lte: '18' },
  },
  {
    label: 'Detached, Finished',
    value: 19,
    rangeObject: { gte: '19', lte: '19' },
  },
  {
    label: 'Detached, Unfinished',
    value: 20,
    rangeObject: { gte: '20', lte: '20' },
  },

  {
    label: 'Attached, Finished',
    value: 21,
    rangeObject: { gte: '21', lte: '21' },
  },
  {
    label: 'Attached, Unfinished',
    value: 22,
    rangeObject: { gte: '22', lte: '22' },
  },
  {
    label: 'Detached, 1-Car',
    value: 23,
    rangeObject: { gte: '23', lte: '23' },
  },
  {
    label: 'Detached, 2-Car',
    value: 24,
    rangeObject: { gte: '24', lte: '24' },
  },
  {
    label: 'Detached, 3+ Car',
    value: 25,
    rangeObject: { gte: '25', lte: '25' },
  },
  {
    label: 'Attached, 1-Car',
    value: 26,
    rangeObject: { gte: '26', lte: '26' },
  },
  {
    label: 'Attached, 2-Car',
    value: 27,
    rangeObject: { gte: '27', lte: '27' },
  },
  {
    label: 'Attached, 3+ Car',
    value: 28,
    rangeObject: { gte: '28', lte: '28' },
  },
  {
    label: 'Carport (Unspecified)',
    value: 30,
    rangeObject: { gte: '30', lte: '30' },
  },
  {
    label: 'Carport, Attached',
    value: 31,
    rangeObject: { gte: '31', lte: '31' },
  },
  {
    label: 'Carport, Detached',
    value: 32,
    rangeObject: { gte: '32', lte: '32' },
  },
  {
    label: 'Carport, Enclosed',
    value: 33,
    rangeObject: { gte: '33', lte: '33' },
  },
  {
    label: 'Carport, Open',
    value: 34,
    rangeObject: { gte: '34', lte: '34' },
  },
  {
    label: 'Carport, 1-Car',
    value: 35,
    rangeObject: { gte: '35', lte: '35' },
  },
  {
    label: 'Carport, 2-Car',
    value: 36,
    rangeObject: { gte: '36', lte: '36' },
  },

  {
    label: 'Carport, 3+ Car',
    value: 37,
    rangeObject: { gte: '37', lte: '37' },
  },
  {
    label: 'Carport, Finished',
    value: 38,
    rangeObject: { gte: '38', lte: '38' },
  },
  {
    label: 'Carport, Unfinished',
    value: 39,
    rangeObject: { gte: '39', lte: '39' },
  },
  {
    label: 'Garage, Basement',
    value: 40,
    rangeObject: { gte: '40', lte: '40' },
  },
  {
    label: 'Garage, Basement, 1-Car',
    value: 41,
    rangeObject: { gte: '41', lte: '41' },
  },
  {
    label: 'Garage, Basement, 2-Car',
    value: 42,
    rangeObject: { gte: '42', lte: '42' },
  },
  {
    label: 'Garage, Basement, 3+ Car',
    value: 43,
    rangeObject: { gte: '43', lte: '43' },
  },
  {
    label: 'Garage, Basement, Finished',
    value: 44,
    rangeObject: { gte: '44', lte: '44' },
  },
  {
    label: 'Garage, Basement, Finished, 1-Car',
    value: 45,
    rangeObject: { gte: '45', lte: '45' },
  },
  {
    label: 'Garage, Basement, Finished, 2-Car',
    value: 46,
    rangeObject: { gte: '46', lte: '46' },
  },
  {
    label: 'Garage, Basement, Finished, 3+ Car',
    value: 47,
    rangeObject: { gte: '47', lte: '47' },
  },
  {
    label: 'Garage, Basement, Unfinished',
    value: 48,
    rangeObject: { gte: '48', lte: '48' },
  },
  {
    label: 'Garage, Basement, Unfinished, 1-Car',
    value: 49,
    rangeObject: { gte: '49', lte: '49' },
  },

  {
    label: 'Garage, Basement, Unfinished, 2-Car',
    value: 50,
    rangeObject: { gte: '50', lte: '50' },
  },
  {
    label: 'Garage, Basement, Unfinished, 3+ Car',
    value: 51,
    rangeObject: { gte: '51', lte: '51' },
  },
  {
    label: 'Garage, Tuckunder',
    value: 52,
    rangeObject: { gte: '52', lte: '52' },
  },
  {
    label: 'Garage, Built-in',
    value: 53,
    rangeObject: { gte: '53', lte: '53' },
  },
  {
    label: 'Garage, Built-in, 1-Car',
    value: 54,
    rangeObject: { gte: '54', lte: '54' },
  },
  {
    label: 'Garage, Built-in, 2-Car',
    value: 55,
    rangeObject: { gte: '55', lte: '55' },
  },
  {
    label: 'Garage, Built-in, Unfinished',
    value: 57,
    rangeObject: { gte: '57', lte: '57' },
  },
  {
    label: 'Garage, Built-in, Finished',
    value: 58,
    rangeObject: { gte: '58', lte: '58' },
  },
  {
    label: 'Garage, 1-Car',
    value: 59,
    rangeObject: { gte: '59', lte: '59' },
  },
  {
    label: 'Garage, 2-Car',
    value: 60,
    rangeObject: { gte: '60', lte: '60' },
  },
  {
    label: 'Garage, 3+ Car',
    value: 61,
    rangeObject: { gte: '61', lte: '61' },
  },
  {
    label: 'Garage, Unfinished, 1-Car',
    value: 62,
    rangeObject: { gte: '62', lte: '62' },
  },
  {
    label: 'Garage, Unfinished, 2-Car',
    value: 63,
    rangeObject: { gte: '63', lte: '63' },
  },
  {
    label: 'Garage, Unfinished, 3+ Car',
    value: 64,
    rangeObject: { gte: '64', lte: '64' },
  },
  {
    label: 'Carport, Detached, Finished',
    value: 65,
    rangeObject: { gte: '65', lte: '65' },
  },
  {
    label: 'Carport, Detached, Unfinished',
    value: 67,
    rangeObject: { gte: '67', lte: '67' },
  },
  {
    label: 'Covered',
    value: 68,
    rangeObject: { gte: '68', lte: '68' },
  },
  {
    label: 'Mixed',
    value: 69,
    rangeObject: { gte: '69', lte: '69' },
  },
  {
    label: 'Garage, Heated',
    value: 70,
    rangeObject: { gte: '70', lte: '70' },
  },
  {
    label: 'Parking Structure',
    value: 189,
    rangeObject: { gte: '189', lte: '189' },
  },
  {
    label: 'Type Not Specified',
    value: 999,
    rangeObject: { gte: '999', lte: '999' },
  },
];

export const lotSizeRanges = [
  { label: '0 to 1,000', value: '0-1000', rangeObject: { gte: 0, lte: 1000 } },
  {
    label: '1,001 to 5,000',
    value: '1001-5000',
    rangeObject: { gte: 1001, lte: 5000 },
  },
  {
    label: '5,001 to 10,000',
    value: '5001-10000',
    rangeObject: { gte: 5001, lte: 10000 },
  },
  {
    label: '10,001 to 20,000',
    value: '10001-20000',
    rangeObject: { gte: 10001, lte: 20000 },
  },
  {
    label: '20,001 to 30,000',
    value: '20001-30000',
    rangeObject: { gte: 20001, lte: 30000 },
  },
  {
    label: '30,001 to 40,000',
    value: '30001-40000',
    rangeObject: { gte: 30001, lte: 40000 },
  },
  {
    label: '40,001 to 50,000',
    value: '40001-50000',
    rangeObject: { gte: 40001, lte: 50000 },
  },
  {
    label: '50,001 to 60,000',
    value: '50001-60000',
    rangeObject: { gte: 50001, lte: 60000 },
  },
  {
    label: '60,001 to 70,000',
    value: '60001-70000',
    rangeObject: { gte: 60001, lte: 70000 },
  },
  {
    label: '70,001 to 80,000',
    value: '70001-80000',
    rangeObject: { gte: 70001, lte: 80000 },
  },
  {
    label: '80,001 to 90,000',
    value: '80001-90000',
    rangeObject: { gte: 80001, lte: 90000 },
  },
  {
    label: '90,001 to 100,000',
    value: '90001-100000',
    rangeObject: { gte: 90001, lte: 100000 },
  },
  {
    label: '100,000+',
    value: '100,000+',
    rangeObject: { gte: 100001, lte: null },
  },
];

export const remainingShareValues = [
  {
    label: '<$100K',
    value: '<$100k',
    min: 0,
    max: 100000,
    tableValue: '<$100k',
  },
  {
    label: '$100K+ to $500K',
    value: '$100k+ to $500k',
    min: 100001,
    max: 500000,
    tableValue: '$100k+ to $500k',
  },
  {
    label: '$500K+ to $1M',
    value: '$500k+ to $1m',
    min: 500001,
    max: 1000000,
    tableValue: '$500k+ to $1m',
  },
  {
    label: '$1M+ to $2M',
    value: '$1m+ to $2m',
    min: 1000001,
    max: 2000000,
    tableValue: '$1m+ to $2m',
  },
  {
    label: '$2M+ to $5M',
    value: '$2m+ to $5m',
    min: 2000001,
    max: 5000000,
    tableValue: '$2m+ to $5m',
  },
  {
    label: '$5M+ to $10M',
    value: '$5m+ to $10m',
    min: 5000001,
    max: 10000000,
    tableValue: '$5m+ to $10m',
  },
  {
    label: '$10M+ to $20M',
    value: '$10m+ to $20m',
    min: 10000001,
    max: 20000000,
    tableValue: '$10m+ to $20m',
  },
  {
    label: '$20M+ to $50M',
    value: '$20m+ to $50m',
    min: 20000001,
    max: 50000000,
    tableValue: '$20m+ to $50m',
  },
  {
    label: '$50M+ to $100M',
    value: '$50m+ to $100m',
    min: 50000001,
    max: 100000000,
    tableValue: '$50m+ to $100m',
  },
  {
    label: '$100M+ to $500M',
    value: '$100m+ to $500m',
    min: 100000001,
    max: 500000000,
    tableValue: '$100m+ to $500m',
  },
  {
    label: '$500M+ to $1B',
    value: '$500m+ to $1b',
    min: 500000001,
    max: 1000000000,
    tableValue: '$500m+ to $1b',
  },
  {
    label: '>$1B',
    value: '>$1b',
    min: 1000000001,
    max: null,
    tableValue: '>$1b',
  },
];

export const planCredits = [
  {
    credit: 500,
    amount: 500,
  },
  {
    credit: 2500,
    amount: 1875,
  },
  {
    credit: 5000,
    amount: 2500,
  },
  {
    credit: 10000,
    amount: 4500,
  },
  {
    credit: 15000,
    amount: 6000,
  },
  {
    credit: 20000,
    amount: 7000,
  },
];

export const industryTypes = [
  'accounting',
  'airlines/aviation',
  'alternative dispute resolution',
  'alternative medicine',
  'animation',
  'apparel & fashion',
  'architecture & planning',
  'arts and crafts',
  'automotive',
  'aviation & aerospace',
  'banking',
  'biotechnology',
  'broadcast media',
  'building materials',
  'business supplies and equipment',
  'capital markets',
  'chemicals',
  'civic & social organization',
  'civil engineering',
  'commercial real estate',
  'computer & network security',
  'computer games',
  'computer hardware',
  'computer networking',
  'computer software',
  'construction',
  'consumer electronics',
  'consumer goods',
  'consumer services',
  'cosmetics',
  'dairy',
  'defense & space',
  'design',
  'e-learning',
  'education management',
  'electrical/electronic manufacturing',
  'entertainment',
  'environmental services',
  'events services',
  'executive office',
  'facilities services',
  'farming',
  'financial services',
  'fine art',
  'fishery',
  'food & beverages',
  'food production',
  'fund-raising',
  'furniture',
  'gambling & casinos',
  'glass, ceramics & concrete',
  'glass, ceramics, & concrete',
  'government administration',
  'government relations',
  'graphic design',
  'health, wellness and fitness',
  'higher education',
  'hospital & health care',
  'hospitality',
  'human resources',
  'import and export',
  'individual & family services',
  'industrial automation',
  'information services',
  'information technology and services',
  'insurance',
  'international affairs',
  'international trade and development',
  'internet',
  'investment banking',
  'investment management',
  'judiciary',
  'law enforcement',
  'law practice',
  'legal services',
  'legislative office',
  'leisure, travel & tourism',
  'leisure, travel, & tourism',
  'libraries',
  'logistics and supply chain',
  'luxury goods & jewelry',
  'machinery',
  'management consulting',
  'maritime',
  'market research',
  'marketing and advertising',
  'mechanical or industrial engineering',
  'media production',
  'medical devices',
  'medical practice',
  'mental health care',
  'military',
  'mining & metals',
  'motion pictures and film',
  'museums and institutions',
  'music',
  'nanotechnology',
  'newspapers',
  'non-profit organization management',
  'oil & energy',
  'online media',
  'outsourcing/offshoring',
  'package/freight delivery',
  'packaging and containers',
  'paper & forest products',
  'performing arts',
  'pharmaceuticals',
  'philanthropy',
  'photography',
  'plastics',
  'political organization',
  'primary/secondary education',
  'printing',
  'professional training & coaching',
  'program development',
  'public policy',
  'public relations and communications',
  'public safety',
  'publishing',
  'railroad manufacture',
  'ranching',
  'real estate',
  'recreational facilities and services',
  'religious institutions',
  'renewables & environment',
  'research',
  'restaurants',
  'retail',
  'security and investigations',
  'semiconductors',
  'shipbuilding',
  'sporting goods',
  'sports',
  'staffing and recruiting',
  'supermarkets',
  'telecommunications',
  'textiles',
  'think tanks',
  'tobacco',
  'translation and localization',
  'transportation/trucking/railroad',
  'utilities',
  'venture capital & private equity',
  'veterinary',
  'warehousing',
  'wholesale',
  'wine and spirits',
  'wireless',
  'writing and editing',
];

export const industryList = [
  { label: 'Banking/Mortgage', value: 'Banking/Mortgage', type: 'b2c' },
  { label: 'Education', value: 'Education', type: 'education' },
  { label: 'Enterprise/B2B', value: 'Enterprise/B2B', type: 'b2b' },
  // { label: 'Financial Services', value: 'Financial Services' },
  { text: 'Gaming', value: 'Gaming', type: 'b2c' },
  { label: 'Healthcare', value: 'Healthcare', type: 'b2c' },
  { label: 'Insurance', value: 'Insurance', type: 'b2c' },
  { label: 'IT/Software', value: 'IT/Software', type: 'b2b' },
  {
    label: 'Hospitality/Food/Travel/Luxury',
    value: 'Hospitality/Food/Travel/Luxury',
    type: 'b2c',
  },
  { label: 'Life Planning', value: 'Life Planning', type: 'b2c' },
  {
    label: 'Media/Creative Industries',
    value: 'Media/Creative Industries',
    type: 'b2b',
  },
  { label: 'Non-Profit', value: 'Non-Profit', type: 'b2c' },
  {
    label: 'Professional Services(Consulting, Law, etc.)',
    value: 'Professional Services(Consulting, Law, etc.)',
    type: 'b2b',
  },
  { label: 'Real Estate', value: 'Real Estate', type: 'b2c' },
  { label: 'Sports', value: 'Sports', type: 'b2c' },
  { label: 'Wealth Planning', value: 'Wealth Planning', type: 'b2c' },
  { label: 'Other', value: 'Other', type: 'b2c' },
];

export const IrsItems = [
  { label: 'Wage', value: 'geo_tax_wages_salaries_tips_etc' },
  { label: 'Household Income', value: 'geo_tax_household_income' },
  { label: 'Wealth Income', value: 'geo_tax_wealth_income' },
  { label: 'Retirement Income', value: 'geo_tax_retirement_income' },
  { label: 'Business Income', value: 'geo_tax_business_income_or_loss' },
  { label: 'Schedule E Income', value: 'geo_tax_schedule_e_income' },
  { label: 'Non Wage Income', value: 'geo_tax_non_wage_income' },
  { label: 'Disposable Income', value: 'geo_tax_disposable_income' },
  { label: 'Total Investable Assets', value: 'geo_tax_investable_assets' },
  { label: 'Capital Gains', value: 'geo_tax_capital_gain_or_loss' },
  { label: 'IRA Deduction', value: 'geo_tax_ira_deduction' },
  { label: 'Total Gifts to Charity', value: 'geo_tax_total_gifts_to_charity' },
  { label: 'Real Estate Taxes', value: 'geo_tax_real_estate_taxes' },
  {
    label: 'Home Mortgage Interest and Points Reported',
    value: 'geo_tax_home_mortgage_interest_and_points_reported_to_you',
  },
];

// TODO: dntfd8933NewProfileView -> on removing this flag, delete IrsItems and rename IrsItemsNew,
// so that it will apply the changes to applied filter and filter cards
export const IrsItemsNew = [
  { label: 'Average Wage', value: 'geo_tax_wages_salaries_tips_etc' },
  { label: 'Average Household Income', value: 'geo_tax_household_income' },
  {
    label: 'Average Business Income',
    value: 'geo_tax_business_income_or_loss',
  },
  {
    label: 'Average Total Investible Assets',
    value: 'geo_tax_investable_assets',
  },
  {
    label: 'Average Total Charitable Donations',
    value: 'geo_tax_total_gifts_to_charity',
  },
];

export const attributes = [
  { label: 'Email (Personal)', value: 'is_personal_emails_included' },
  { label: 'Email (Work)', value: 'is_work_emails_included' },
  { label: 'Phone (Mobile)', value: 'is_mobile_phones_included' },
  { label: 'Phone (Other)', value: 'is_other_phones_included' },
  { label: 'Household Member', value: 'is_household_members_included' },
  { label: 'Home Address', value: 'is_home_address_included' },
  { label: 'Twitter', value: 'is_twitter_included' },
  { label: 'Facebook', value: 'is_facebook_included' },
  { label: 'Linkedin', value: 'is_linkedin_included' },
  { label: 'Notes', value: 'is_notes_included' },
  { label: 'Neustar ID', value: 'is_b2c_id_included' },
  { label: 'AI Inspected Home Address', value: 'is_home_address_verified' },
  {
    label: 'AI Inspected Home Owner Status',
    value: 'is_home_ownership_verified',
  },
  { label: 'AI Inspected Age', value: 'is_age_verified' },
  { label: 'AI Inspected Sex', value: 'is_gender_verified' },
  { label: 'AI Inspected Career', value: 'is_career_verified' },
  { label: 'AI Inspected Inferred Income', value: 'is_income_verified' },
  { label: 'AI Inspected Wealth Segment', value: 'is_wealth_verified' },
  // { label: 'Professional', value: 'is_b2b_id_included' },
  { label: 'Household', value: 'is_b2c_id_included' },
  { label: 'Professional Plus', value: 'li_only' },
  { label: 'Medical', value: 'is_medical_included' },
];

export const wealthModelItems = [
  { label: 'Age', value: 'is_age_included' },
  { label: 'Property Ownership', value: 'is_norm_home_owner_included' },
  { label: 'Property Value', value: 'is_home_value_estimate_included' },
  { label: 'Inferred Income', value: 'is_inferred_salary_included' },
  {
    label: 'Zip+4 Tax Info - Investable Assets',
    value: 'is_geo_tax_investable_assets_included',
  },
  { label: 'Career Information', value: 'is_b2b_id_included' },
  {
    label: 'Remaining Share Balance(s)',
    value: 'is_remaining_share_value_sum_included',
  },
  { label: 'Wealth Triggers', value: 'has_wealth_triggers' },
];

export const dashboardBoxItems = {
  TriggersOverview: 'Trigger Statistics',
  OpportunitiesbyFilters: 'Opportunities by Filters',
  CSVImport: 'CSV Import',
  WeeklyTip: 'Weekly Tip',
  SuggestedVideo: 'Suggested Video',
  ExternalRelationshipPaths: 'External Relationship Paths',
  WeeklyEngagement: 'Weekly Engagement',
  TeamMembers: 'Team Members',
  NetworkDNA: 'Network DNA',
  TeamRelationshipPaths: 'Team Relationship Paths',
  Companies: 'Companies',
  CompanyViabiliy: 'Company Viabiliy',
  Industries: 'Industries',
  TitleRanks: 'Title Ranks',
  InferredSalaryRanges: 'Inferred Salary Ranges',
  WealthSegment: 'Wealth Segment',
  Cities: 'Cities',
  StatesandTerritories: 'States and Territories',
  ZipCodes: 'Zip Codes',
  CollegesandUniversities: 'Colleges and Universities',
  HouseholdInterests: 'Modeled Interests',
  Tags: 'Tags',
  WealthTriggerOpportunities: 'Wealth Trigger Statistics', // Dashboard2024 (GTM only)
};

export const storiesList = [
  { value: '0', label: '0 story' },
  { value: '1', label: '1 story' },
  { value: '2', label: '2 story' },
  { value: '3', label: '3 story' },
  { value: '4', label: '4 story' },
  { value: '5', label: '5 story' },
  { value: '6', label: '6 story' },
  { value: '7', label: '7 story' },
  { value: '8', label: '8 story' },
  { value: '9', label: '9 story' },
  { value: '10', label: '10 story' },
  { value: '11', label: '11 story' },
  { value: '12', label: '12 story' },
  { value: '13', label: '13 story' },
  { value: '14', label: '14 story' },
  { value: '15', label: '15 story' },
];

export const periodList = [
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

export const urlNames = {
  dashboard: 'Dashboard',
  opportunities: 'Opportunities',
  'super-connectors': 'Super Connectors',
  people: 'Prospect Finder',
  savedsearches: 'Saved Searches',
  connect: 'Import/Export',
  notifications: 'Notifications',
};

export const networkListColumns = [
  {
    label: 'City',
    value: 'location_city',
  },
  {
    label: 'Company',
    value: 'org_name',
  },
  {
    label: 'Degree',
    value: 'degree',
  },
  {
    label: 'Home Address',
    value: 'home_address',
  },
  {
    label: 'Inferred Income',
    value: 'inferred_salary',
  },
  {
    label: 'Mobile Number',
    value: 'mobile_phones',
  },
  {
    label: 'Mutual',
    value: 'mutual',
  },
  {
    label: 'Name',
    value: 'full_name',
  },
  {
    label: 'Notes',
    value: 'note',
  },
  {
    label: 'Personal Email',
    value: 'personal_emails',
  },
  {
    label: 'Professional Email',
    value: 'work_emails',
  },
  {
    label: 'Relationship Strength',
    value: 'relationship_status',
  },
  {
    label: 'Relationship Type',
    value: 'relationship_type',
  },
  {
    label: 'School',
    value: 'schools',
  },
  {
    label: 'State',
    value: 'location_state',
  },
  {
    label: 'Tags',
    value: 'tags',
  },
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Trigger',
    value: 'event_type',
  },
  {
    label: 'Trigger Date',
    value: 'event_date',
  },
  {
    label: 'Trigger Description',
    value: 'event_description',
  },
  {
    label: 'Trigger Value',
    value: 'event_monetary_value',
  },
  {
    label: 'Wealth Segment',
    value: 'wealth_estimate',
  },
  {
    label: 'Zip Code',
    value: 'home_postal_code',
  },
];

/** We can't rely on the labels, as they may change in the future.
 * Instead, we pass a key to the API, which expects a value like "avtr-12."
 * keep the file name and icon name to match the key for better clarity. */
export const avatars = [
  { Icon: Avatar1, label: 'Investment Oracle', key: 'avtr-01' },
  { Icon: Avatar2, label: 'Market Maven', key: 'avtr-02' },
  { Icon: Avatar3, label: 'Quota Crusher', key: 'avtr-03' },
  { Icon: Avatar4, label: 'Closer Upper', key: 'avtr-04' },
  { Icon: Avatar5, label: 'Money Maestro', key: 'avtr-05' },
  { Icon: Avatar6, label: 'Stock Doc', key: 'avtr-06' },
  { Icon: Avatar7, label: 'Money Honey', key: 'avtr-07' },
  { Icon: Avatar8, label: 'Captain AUM-Mo', key: 'avtr-08' },
];

const functionFlags = {
  free_trial: 'free_trial',
  premium_wealth_triggers: 'premium_wealth_triggers',
  paragon: 'advanced_integrations',
  sso: 'sso',
  northwestern_mutual: 'nwm',
  nexis: 'nexis',
  nexis_admin: 'nexis_admin',
  multiseat: 'multiseat',
  team_suppression: 'team_suppression',
  invited_user: 'invited_user',
  hide_suppress_tag: 'hide_suppress_tag',
  hide_shared_csv_files: 'hide_shared_csv_files',
  hide_moneta_contact_tag: 'hide_moneta_contact_tag',
  allow_crunchbase_exports: 'allow_crunchbase_exports',
  allow_increased_contact_export_record_limit:
    'allow_increased_contact_export_record_limit',
  profile_imports: 'profile_imports',
  saved_searches: 'saved_searches',
  profile_location: 'profile_location',
  company_info: 'company_info',
  profile_contact_info: 'profile_contact_info',
  wealth_properties: 'wealth_properties',
  all_profiles: 'all_profiles',
};

// To make the object immutable (optional but recommended):
export const FunctionFlags = Object.freeze(functionFlags);

const standardEventTypes = {
  News: 'news',
  CompanyChange: 'company change',
  JobTitleChange: 'job title change',
};

const premiumEventTypes = {
  PropertyPurchase: 'property purchase',
  PropertySale: 'property sale',
  UnicornStatusAchieved: 'unicorn status achieved',
  CompanyAcquired: 'company was acquired',
  InvestmentReceived: 'investment received',
  Ipo: 'ipo',
  IpoFiling: 'ipo filing',
  IpoSpac: 'ipo spac',
  InsiderStockSale: 'insider stock sale',
  InsiderStockPurchase: 'insider stock purchase',
  InsiderStockAcquisition: 'insider stock acquisition',
  PropertyListing: 'property listing',
  Deceased: 'deceased',
};

// To make the objects immutable (optional but recommended):
export const StandardEventTypes = Object.freeze(standardEventTypes);
export const PremiumEventTypes = Object.freeze(premiumEventTypes);

const premiumProspectFinderColumns = {
  WealthSegment: 'wealth_estimate',
  InferredIncome: 'inferred_salary',
};

export const PremiumProspectFinderColumns = Object.freeze(
  premiumProspectFinderColumns,
);

export const DashboardViewItems = Object.freeze({
  TriggersOverview: true,
  OpportunitiesbyFilters: true,
  CSVImport: true,
  WeeklyTip: true,
  SuggestedVideo: true,
  ExternalRelationshipPaths: true,
  WeeklyEngagement: true,
  TeamMembers: true,
  NetworkDNA: true,
  TeamRelationshipPaths: true,
  Companies: true,
  CompanyViabiliy: true,
  Industries: true,
  TitleRanks: true,
  InferredSalaryRanges: true,
  WealthSegment: true,
  Cities: true,
  StatesandTerritories: true,
  ZipCodes: true,
  CollegesandUniversities: true,
  HouseholdInterests: true,
  Tags: true,
});

export const DashbaordViewDefaultItems = Object.freeze([
  'TriggersOverview',
  'OpportunitiesbyFilters',
  'SuggestedVideo', // removed for GTM
  'WeeklyTip', // removed for GTM
]);

export const DashboardViewNewDefaultItems = Object.freeze([
  'TriggersOverview',
  'WealthTriggerOpportunities',
  'NetworkDNA',
  'WeeklyEngagement',
]);

export const DashboardGtmUngroupedLabels = Object.freeze([
  'TriggersOverview',
  'WealthTriggerOpportunities', // always enabled for GTM
  'TeamMembers',
  'ExternalRelationshipPaths',
  // 'Tags', // removed for GTM?
]);

export const DashboardViewUngroupedLabels = Object.freeze([
  'TriggersOverview',
  'CSVImport', // removed for GTM
  'WeeklyTip', // removed for GTM
  'OpportunitiesbyFilters', // removed for GTM
  'SuggestedVideo', // removed for GTM
  'WeeklyEngagement',
  'TeamMembers',
  'ExternalRelationshipPaths',
  'NetworkDNA',
  'TeamRelationshipPaths', // removed for GTM
  'Tags',
]);

export const DashboardViewBusinessInsightsLabels = Object.freeze([
  'Companies',
  'CompanyViabiliy',
  'Industries',
  'TitleRanks',
]);

export const DashboardViewWealthInsightsLabels = Object.freeze([
  'InferredSalaryRanges', // PRO users get fudged data
  'WealthSegment', // PRO users get fudged data
]);

export const DashboardViewLocationInsightsLabels = Object.freeze([
  'Cities',
  'StatesandTerritories',
  'ZipCodes',
]);

export const DashboardGtmEducationInsightsLabels = Object.freeze([
  'CollegesandUniversities',
]);

export const DashboardViewEducationInsightsLabels = Object.freeze([
  'CollegesandUniversities',
  'HouseholdInterests', // removed for GTM
]);
