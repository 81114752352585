import './antdStyles.scss';
import './index.css';

import { ConfigProvider } from 'antd';
import { useEffect, useState } from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { inject, observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';

import { usePostHog } from 'posthog-js/react';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import BasicLayout from './layouts/BasicLayout';
import UserLayouts from './layouts/UserLayout';
import Exception from './components/Exception';
import Progress from './components/Progress';

import RelationshipIntelligence from './pages/RelationshipIntelligence/RelationshipIntelligence';
import OpportunitiesNew from './pages/RelationshipIntelligence/OpportunitiesNew';
import SuperConnectors from './pages/RelationshipIntelligence/SuperConnectors';
import Dashboard from './pages/RelationshipIntelligence/Dashboard';
import DashboardNew from './pages/RelationshipIntelligence/DashboardNew';

import ProspectFinder from './pages/ProspectFinder/ProspectFinder';
import PeopleView from './pages/ProspectFinder/PeopleView.tsx';
import InsightsView from './pages/ProspectFinder/InsightsView';
import MapView from './pages/RelMap/NetworkMap';

import Filters from './pages/Filters';
import Notifications from './pages/Notifications';
import Connect from './pages/Connect';
// import Settings from './pages/Settings';

import Account from './pages/Account/Account';
import BaseView from './pages/Account/BaseView';
import SecurityView from './pages/Account/SecurityView';
import AccountProfile from './pages/Account/AccountProfile';

import Login from './pages/User/Login';
import LoginCallBack from './pages/User/LoginCallBack';
import ForgotPassword from './pages/User/ForgotPassword';
import ForgotPasswordSent from './pages/User/ForgotPasswordSent';
import ZendeskJWTCallBack from './pages/User/ZendeskJWTCallBack';

import VipContent from './pages/VipRegister';
import MaxioCallBack from './pages/User/MaxioCallBack';
import SignUpCallBack from './pages/User/SignUpCallBack';
import ContactImportCallback from './pages/User/ContactImportCallback';
import ExportDownloadEmailRedirect from './pages/User/ExportDownloadEmailRedirect';
import CrmCallback from './pages/User/CrmCallback';
import { getCrmNames } from './utils/crmMethods';

import { setAxiosTokenInterceptor } from './components/Auth0AxiosInterceptor';
import { setAppTheme } from './utils/general';
import { setUserType } from './utils/userTypeAuthority';
import CreditsPage from './pages/Account/CreditsPage';
import CreditsCallbackPage from './pages/Account/CreditsCallbackPage';
import LoaderAnimation from './components/AidLoader';
import LogOut from './pages/User/LogOut.tsx';

const Resources = () => {
  return (
    <iframe
      src="https://support.aidentified.com/"
      style={{ width: '100%', height: '400px' }}
    />
  );
};

const App = inject('dataStore')(
  inject('modalDataStore')(
    observer((props) => {
      const [hasToken, setHasToken] = useState(false);
      const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
      const flags = useFlags();
      const hostArray = window.location.host.split('.');
      const isBlueridge = hostArray[0] === 'blueridge';
      const posthog = usePostHog();
      /**
       * Check if the user is already authenticated
       * Wait untill @isLoading is completed
       * (otherwise @isAuthenticated won't be updated correctly)
       * else if @isAuthenticated get the token and
       * set it in the axios header and after that
       * load the components.
       */

      useEffect(() => {
        if (!isLoading && isAuthenticated) {
          try {
            getIdTokenClaims().then((token) => {
              if (token) setAxiosTokenInterceptor(token.__raw);
              setUserType().then((user) => {
                if (user) {
                  props.dataStore.currentUser = user;
                }
                setHasToken(true);
              });
            });
          } catch (error) {
            console.log(error);
          }
        } else if (!isLoading) {
          setHasToken(true);
        }
        setAppTheme();
      }, [isLoading, isAuthenticated]);

      useEffect(() => {
        if (
          !isLoading &&
          isAuthenticated &&
          props.dataStore.currentUser &&
          props.dataStore.currentUser.ownerId
        ) {
          const user = props.dataStore.currentUser;
          try {
            posthog?.identify(user.ownerId, {
              id: user.ownerId, // Required if user is logged in, default creates anonymous ID
              email: user.email,
              full_name: user.full_name,
              user_type: user.type,
              white_label: user.slug,
              industry: user.industry,
              account_rep: user.account_rep?.full_name,
              title: user.title,
              referrer_code: user.referrer_code,
              crm: getCrmNames(user.crm),
              credits: user.creditCount,
              company: user.company || '',
            });
          } catch (error) {
            console.log(error);
          }
        }
      }, [
        isLoading,
        isAuthenticated,
        posthog,
        props.dataStore?.currentUser?.ownerId,
      ]);

      const showCompanyProfile = (id, orgName) => {
        if (!id) return;
        posthog?.capture('company_profile_opened');
        // click on any company name
        props.modalDataStore.resetProfileModal();
        props.modalDataStore.resetCompanyProfileModal();
        const { companyProfileModal } = props.modalDataStore;
        companyProfileModal.id = id;
        companyProfileModal.orgName = orgName;
        companyProfileModal.isVisible = true;
        if (
          props.modalDataStore.trackModal &&
          props.modalDataStore.trackModal.length > 0
        ) {
          props.modalDataStore.trackModal.push({
            modal: 'company',
            data: { id, orgName },
          });
        }
      };

      if (isLoading || !hasToken)
        return (
          <div className="loader-wrapper">
            <LoaderAnimation loaderId="app-loader" />;
          </div>
        );

      return (
        <ConfigProvider
          theme={{
            token: {
              fontFamily: 'Rubik-Regular',
            },
            components: {
              Radio: {
                colorPrimary: 'var(--color-primary)',
              },
            },
          }}
        >
          <BrowserRouter future={{ v7_startTransition: true }}>
            <Routes>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <BasicLayout />
                  </PrivateRoute>
                }
              >
                <Route
                  path="relationship-intelligence"
                  element={<RelationshipIntelligence />}
                >
                  <Route
                    path="dashboard"
                    element={
                      flags && flags.dasboard2024 ? (
                        <DashboardNew />
                      ) : (
                        <Dashboard />
                      )
                    }
                  />
                  <Route path="opportunities" element={<OpportunitiesNew />} />
                  <Route
                    path="super-connectors"
                    element={<SuperConnectors />}
                  />
                  <Route
                    index
                    element={
                      <Navigate to="/relationship-intelligence/dashboard" />
                    }
                  />
                  <Route path="*" element={<Exception />} />
                </Route>

                <Route path="prospect-finder" element={<ProspectFinder />}>
                  <Route
                    path="people"
                    element={
                      <PeopleView showCompanyProfile={showCompanyProfile} />
                    }
                  />
                  <Route
                    path="events"
                    element={
                      <Navigate
                        to={`/prospect-finder/people${window.location.search}`}
                      />
                    }
                  />
                  <Route
                    path="companies"
                    element={
                      <InsightsView
                        isCompany
                        showCompanyProfile={showCompanyProfile}
                      />
                    }
                  />
                  <Route path="map" element={<MapView />} />

                  <Route
                    index
                    element={<Navigate to="/prospect-finder/people" />}
                  />
                  <Route path="*" element={<Exception />} />
                </Route>

                <Route path="savedsearches" element={<Filters />} />
                {/* To handle any redirection to filters from outside the app */}
                <Route
                  path="filters"
                  element={<Navigate to="/savedsearches" />}
                />
                <Route
                  path="notifications"
                  element={
                    flags && flags.dntfd8933NewProfileView ? (
                      <Navigate to="/account/notifications" />
                    ) : (
                      <Notifications />
                    )
                  }
                />

                <Route path="connect" element={<Connect />}>
                  <Route path="status" />
                  <Route path="import" />
                  <Route path="export" />
                  <Route index element={<Navigate to="/connect/status" />} />
                  <Route path="*" element={<Exception />} />
                </Route>

                {/* <Route path="settings" element={<Settings />} /> */}

                <Route path="account" element={<Account />}>
                  <Route
                    path="profile"
                    element={
                      flags && flags.dntfd8933NewProfileView ? (
                        <AccountProfile />
                      ) : (
                        <BaseView />
                      )
                    }
                  />
                  <Route path="security" element={<SecurityView />} />
                  <Route path="credits" element={<CreditsPage />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route
                    path="credits-callback"
                    element={<CreditsCallbackPage />}
                  />
                  {/* TODO: Remove this after resources is added */}
                  {props.dataStore.currentUser.isAdmin && (
                    <Route path="/account/resources" element={<Resources />} />
                  )}
                  <Route index element={<Navigate to="/account/profile" />} />
                  <Route path="*" element={<Exception />} />
                </Route>
                <Route
                  path="/download-contact-csv/:fileId/:fileName"
                  element={<ExportDownloadEmailRedirect />}
                />
                <Route
                  index
                  element={
                    <Navigate to="/relationship-intelligence/dashboard" />
                  }
                />
              </Route>

              <Route path="user" element={<UserLayouts />}>
                <Route path="login" element={<Login />} />
                <Route path="login/callback" element={<LoginCallBack />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="logout" element={<LogOut />} />
                <Route
                  path="forgot-password-sent"
                  element={<ForgotPasswordSent />}
                />
                <Route
                  path="zd-jwt"
                  element={
                    <PrivateRoute>
                      <ZendeskJWTCallBack />
                    </PrivateRoute>
                  }
                />
                {/* /user/registration  path is shown only if logged in
                 * and for non-admin, non-corporate users
                 * who are not registered a plan
                 *
                 * in case of maxio - this is less likely to happen.
                 * since if any failure during signup or registration,
                 * all apis will give no user found error and on this api.jsx redirect the user to signupcallback
                 * TODO: Remove this path after confirming this is no longer needed*/}
                <Route
                  element={
                    props.dataStore.currentUser &&
                    (props.dataStore.currentUser.isAdmin ||
                      props.dataStore.currentUser.isReg ||
                      props.dataStore.currentUser.isExistingUser) ? (
                      <Navigate to="/relationship-intelligence" />
                    ) : isAuthenticated ? (
                      <Navigate to="/user/login/callback" />
                    ) : (
                      <Navigate to="/user/login" />
                    )
                  }
                  path="registration"
                />

                <Route index element={<Navigate to="/user/login" />} />
              </Route>

              <Route path="vip" element={<UserLayouts />}>
                <Route path="register" element={<VipContent />} />
                <Route path="signup/callback" element={<SignUpCallBack />} />
                <Route path="signup/maxio" element={<MaxioCallBack />} />
                <Route index element={<Navigate to="/vip/register" />} />
              </Route>

              <Route path="exception" element={<Exception />} />
              <Route path="crm/callback" element={<CrmCallback />} />
              <Route
                path="contact/callback"
                element={
                  <PrivateRoute>
                    <ContactImportCallback />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Exception />} />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      );
    }),
  ),
);

export default App;
