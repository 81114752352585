import './index.scss';
import { inject, observer } from 'mobx-react';
import { Empty } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import NetworkDnaImage from '../../assets/icons/ai_dna.svg?react';
import { commarize } from '../../utils/general';

const NetworkDNAData = (props) => {
  const { count, value, label } = props;
  return (
    <div
      className="network-dna-item"
      style={{ borderRight: '1px solid var(--color-light-shade)' }}
    >
      <div className="dna-count">{commarize(count)} </div>
      <div
        style={{
          color: 'var(--color-primary)',
          fontWeight: '400',
          paddingRight: 3,
        }}
      >
        {value ? value.toTitleCase() : ''}
      </div>
      <div
        style={{
          color: 'var(--color-primary)',
          fontWeight: '300',
          opacity: '0.5',
        }}
      >
        {label}
      </div>
    </div>
  );
};

const NetworkDNA = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      industry,
      salary,
      title,
      city,
      stockPerformance,
      company,
      wealthSegment,
      zipCodes,
      states,
      colleges,
      houseHoldInterests,
    } = props;

    return (
      <div className="network-dna">
        <div className="network-dna-left">
          <div>Network DNA</div>
          <div className="dna-text">
            Discover strengths in your Network DNA and leverage for success.
            Expand your network through teams and discover more.
          </div>
          <NetworkDnaImage />
        </div>
        <div className="network-dna-right">
          <CloseCircleFilled
            className="dna-close-button"
            onClick={props.onCloseButtonClick}
          />
          {!(
            (industry &&
              industry.top_industries &&
              industry.top_industries.length > 0) ||
            (stockPerformance &&
              stockPerformance.top_stock_performance &&
              stockPerformance.top_stock_performance.length > 0) ||
            (company &&
              company.top_ai_org_ids &&
              company.top_ai_org_ids.length > 0) ||
            (title && title.top_titles && title.top_titles.length > 0) ||
            (wealthSegment &&
              wealthSegment.top_wealth_estimate &&
              wealthSegment.top_wealth_estimate.length > 0) ||
            (salary &&
              salary.top_salary_ranges &&
              salary.top_salary_ranges.length > 0) ||
            (zipCodes &&
              zipCodes.top_zip_codes &&
              zipCodes.top_zip_codes.length > 0) ||
            (city && city.top_cities && city.top_cities.length > 0) ||
            (states && states.top_states && states.top_states.length > 0) ||
            (colleges &&
              colleges.top_colleges &&
              colleges.top_colleges.length > 0) ||
            (houseHoldInterests &&
              houseHoldInterests.top_interests &&
              houseHoldInterests.top_interests.length > 0)
          ) ? (
            <div className="dna-empty">
              <Empty />
            </div>
          ) : (
            <>
              {industry &&
                industry.top_industries &&
                industry.top_industries.length > 0 && (
                  <NetworkDNAData
                    count={industry.top_industries[0].unique_count}
                    value={industry.top_industries[0].key}
                    label={'Industries'}
                  />
                )}
              {stockPerformance &&
                stockPerformance.top_stock_performance &&
                stockPerformance.top_stock_performance.length > 0 && (
                  <NetworkDNAData
                    count={
                      stockPerformance.top_stock_performance[0].unique_count
                    }
                    value={stockPerformance.top_stock_performance[0].key}
                    label={'Company Viability'}
                  />
                )}
              {company &&
                company.top_ai_org_ids &&
                company.top_ai_org_ids.length > 0 && (
                  <NetworkDNAData
                    count={company.top_ai_org_ids[0].unique_count}
                    value={company.top_ai_org_ids[0].key}
                    label={'Companies'}
                  />
                )}
              {title && title.top_titles && title.top_titles.length > 0 && (
                <NetworkDNAData
                  count={title.top_titles[0].unique_count}
                  value={title.top_titles[0].key}
                  label={'Title Rank'}
                />
              )}
              {wealthSegment &&
                wealthSegment.top_wealth_estimate &&
                wealthSegment.top_wealth_estimate.length > 0 && (
                  <NetworkDNAData
                    count={wealthSegment.top_wealth_estimate[0].unique_count}
                    value={wealthSegment.top_wealth_estimate[0].key}
                    label={'Wealth Segment'}
                  />
                )}
              {salary &&
                salary.top_salary_ranges &&
                salary.top_salary_ranges.length > 0 && (
                  <NetworkDNAData
                    count={salary.top_salary_ranges[0].unique_count}
                    value={salary.top_salary_ranges[0].key}
                    label={'Infered Salary Ranges'}
                  />
                )}
              {zipCodes &&
                zipCodes.top_zip_codes &&
                zipCodes.top_zip_codes.length > 0 && (
                  <NetworkDNAData
                    count={zipCodes.top_zip_codes[0].unique_count}
                    value={zipCodes.top_zip_codes[0].key}
                    label={'Zip Codes'}
                  />
                )}
              {city && city.top_cities && city.top_cities.length > 0 && (
                <NetworkDNAData
                  count={city.top_cities[0].unique_count}
                  value={city.top_cities[0].key}
                  label={'Cities'}
                />
              )}
              {states && states.top_states && states.top_states.length > 0 && (
                <NetworkDNAData
                  count={states.top_states[0].unique_count}
                  value={states.top_states[0].key}
                  label={'States & Territories'}
                />
              )}
              {colleges &&
                colleges.top_colleges &&
                colleges.top_colleges.length > 0 && (
                  <NetworkDNAData
                    count={colleges.top_colleges[0].unique_count}
                    value={colleges.top_colleges[0].key}
                    label={'Colleges/Universities'}
                  />
                )}
              {houseHoldInterests &&
                houseHoldInterests.top_interests &&
                houseHoldInterests.top_interests.length > 0 && (
                  <NetworkDNAData
                    count={houseHoldInterests.top_interests[0].unique_count}
                    value={houseHoldInterests.top_interests[0]?.key}
                    label={'Modeled Interests'}
                  />
                )}
            </>
          )}
        </div>
      </div>
    );
  }),
);

export default NetworkDNA;
