import './index.scss';

import moment from 'moment';
import { inject, observer } from 'mobx-react';

import ExportsIcon from '../../assets/icons/icn-dash-user-stat-exports.svg?react';
import ImportsIcon from '../../assets/icons/icn-dash-user-stat-imports.svg?react';
import ProfileViews from '../../assets/icons/icn-dash-user-stat-views.svg?react';
import StatSearches from '../../assets/icons/icn-dash-user-stat-searches.svg?react';

import { commarize } from '../../utils/general';
import { LoadingSpinner } from '../../components/AiFunctionalComponents';

const WeeklyEngagement = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { data, isLoading } = props;

    const renderHeader = () => {
      return (
        <div className="flex flex-col" style={{ width: '25%' }}>
          <div className="header">Weekly Engagement</div>
          <div className="header-description">{renderTimeframe()}</div>
        </div>
      );
    };

    const renderTimeframe = () => {
      const sd = moment();
      const ed = moment();
      return (
        <span>
          {sd.subtract(7, 'days').format('MMMM D, YYYY')} -{' '}
          {ed.format('MMMM D, YYYY')}
        </span>
      );
    };

    const renderStatistic = (label, val, icon) => {
      return (
        <div
          className="flex"
          style={{
            padding: '28px 24px',
            gap: '20px',
            background: 'var(--color-blue-50)',
            color: 'var(--color-blue-500)',
            borderRadius: '8px',
          }}
        >
          <div style={{ flexGrow: 0 }}>{icon}</div>
          <div className="flex flex-col" style={{ flexGrow: 1 }}>
            <div className="count">{val ? commarize(val) : 0}</div>
            <div className="engagement-item-header">{label}</div>
          </div>
        </div>
      );
    };

    return (
      <div
        className={`
          dashboard-item weekly-engagement-new
          relative 
          w-full 
          flex
          flex-row
          rounded-16 
          `}
        style={{ padding: '44px', gap: '20px' }}
      >
        {renderHeader()}

        <div
          className={`grid grid-cols-1 sm:grid-cols-2 mdlg:grid-cols-4 relative rounded-16 ${isLoading ? 'aid-div-disable' : 'aid-div-enable'}`}
          style={{ flexGrow: 1, gap: '16px' }}
        >
          <LoadingSpinner isLoading={isLoading} />
          {renderStatistic(
            'Searches',
            data.search_count,
            <StatSearches width={36} height={36} />,
          )}
          {renderStatistic(
            'Profile Views',
            data.profiles_viewed_count,
            <ProfileViews width={36} height={36} />,
          )}
          {renderStatistic(
            'Exports',
            data.export_count,
            <ExportsIcon width={36} height={36} />,
          )}
          {renderStatistic(
            'CSV Imports',
            data.csv_import_count,
            <ImportsIcon width={36} height={36} />,
          )}
        </div>
      </div>
    );
  }),
);

export default WeeklyEngagement;
