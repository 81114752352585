import './index.scss';

import { inject, observer } from 'mobx-react';
import moment from 'moment';

import CompanyIcon from '../../assets/icons/icn-dash-companies.svg?react';
import CreditsIcon from '../../assets/icons/icn-dash-credits.svg?react';
import OpportunitiesIcon from '../../assets/icons/icn-dash-new-opps.svg?react';
import PeoplesIcon from '../../assets/icons/icn-dash-people.svg?react';
import TriggerValue from '../../assets/icons/icn-dash-total-triggers.svg?react';
import EstWealthIcon from '../../assets/icons/icn-dash-total-value.svg?react';

import EditIcon from '../../assets/icons/ai_edit.svg?react';

import { commarize, getTimeofDayGreeting } from '../../utils/general';
import { LoadingSpinner } from '../AiFunctionalComponents';
import { AiButton } from '../AiButton';
import MultiFilterSelect from '../MultiFilterSelect';

interface StatisticOptions {
  label: string;
  icon: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  val: any;
  bg: string;
  labelColor: string;
  formatFunc: (string) => string;
}

const DashboardHeaderNew = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const { data, isLoading, onEditDashboardView } = props;

    const renderHeader = () => {
      const date = moment();
      return (
        <div className="flex flex-col gap-8 place-items-start">
          <h3 className="header">Dashboard</h3>
          <time
            className="date-label"
            dateTime={date.format('dddd, MMMM D, YYYY')}
          >
            {date.format('dddd, MMMM D, YYYY')}
          </time>
          <span className="margin-t-auto">
            {getTimeofDayGreeting(date)},{' '}
            {(props.dataStore.currentUser.full_name || '').split(' ')[0]}
          </span>
        </div>
      );
    };

    const renderStatistic = (options: StatisticOptions): JSX.Element => {
      return (
        <>
          <div
            className="flex gap-20"
            style={{
              backgroundColor: options.bg,
              padding: '28px 24px',
              borderRadius: 8,
            }}
          >
            <div style={{}}>{options.icon}</div>
            <div
              className="flex flex-col"
              style={{ color: options.labelColor }}
            >
              <span className="trigger-statistic-value">
                {options.val ? options.formatFunc(options.val) : 0}
              </span>
              <span className="trigger-statistic-label">{options.label}</span>
            </div>
          </div>
        </>
      );
    };

    const renderSavedSearches = () => {
      return (
        <div className="flex flex-col">
          <label
            htmlFor="saved-searches"
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '22px',
            }}
          >
            Based on your Saved Searches
          </label>

          <div className="ri-multiple-filter">
            <MultiFilterSelect
              updateAfterSelect={() => props.onSavedSearchChange()}
              value={props.globalDataStore.multipleFilters.selectedId}
              variant="outlined"
            />
          </div>
        </div>
      );
    };

    const renderDashboardLayoutEditBtn = () => {
      return (
        <AiButton
          buttonType="secondary"
          buttonStyle={{
            flexGrow: 0,
            color: 'var(--color-gray-400)',
            alignSelf: 'flex-end',
            width: 'auto',
            padding: '0px',
            border: 'none',
          }}
          variant="ghost"
          leftIcon={<EditIcon width={20} height={20} />}
          onClick={() => onEditDashboardView()}
        >
          Edit Dashboard View
        </AiButton>
      );
    };

    const renderStatistics = () => {
      return (
        <div
          className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
          style={{ gap: 16 }}
        >
          {/* top row */}

          {renderStatistic({
            label: 'New Opportunities',
            icon: <OpportunitiesIcon width={36} height={36} />,
            val: data.total_opportunities,
            bg: 'var(--color-blue-100)',
            labelColor: 'var(--color-blue-700)',
            formatFunc: commarize,
          })}
          {renderStatistic({
            label: 'Est. Total Trigger Value',
            icon: <EstWealthIcon width={36} height={36} />,
            val: data.total_monetary_value,
            bg: 'var(--color-green-300)',
            labelColor: 'var(--color-teal-700)',
            formatFunc: commarize,
          })}
          {renderStatistic({
            label: 'Total Wealth Triggers',
            icon: <TriggerValue width={36} height={36} />,
            val: data.total_event_count,
            bg: 'var(--color-yellow-200)',
            labelColor: 'var(--color-plum-600)',
            formatFunc: commarize,
          })}

          {/* bottom row */}
          {renderStatistic({
            label: 'Credits Available',
            icon: <CreditsIcon width={36} height={36} />,
            val: 1000,
            bg: 'var(--color-teal-75)',
            labelColor: 'var(--color-blue-600)',
            formatFunc: commarize,
          })}
          {renderStatistic({
            label: 'People Impacted',
            icon: <PeoplesIcon width={36} height={36} />,
            val: data.total_executives,
            bg: 'var(--color-green-50)',
            labelColor: 'var(--color-teal-400)',
            formatFunc: commarize,
          })}
          {renderStatistic({
            label: 'Companies Impacted',
            icon: <CompanyIcon width={36} height={36} />,
            val: data.total_org_count,
            bg: 'var(--color-yellow-50)',
            labelColor: 'var(--color-plum-400)',
            formatFunc: commarize,
          })}
        </div>
      );
    };

    return (
      <div
        className={`
          flex 
          flex-col
          sm:flex-row
          w-full 
          relative 
          rounded-16 
          justify-between
          ${isLoading ? 'aid-div-disable' : 'aid-div-enable'}`}
        style={{
          padding: '44px 40px',
          background: 'var(--color-white)',
        }}
      >
        {renderHeader()}
        <div className="flex flex-col" style={{ gap: '16px' }}>
          <div className="flex flex-col sm:flex-row justify-between">
            {renderSavedSearches()}
            {renderDashboardLayoutEditBtn()}
          </div>
          {renderStatistics()}
        </div>
        <LoadingSpinner isLoading={isLoading} />
      </div>
    );
  }),
);

export default DashboardHeaderNew;
