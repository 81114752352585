import { Bar } from 'react-chartjs-2';
import { commarize } from '../../utils/general';

interface DashboardTriggerLineChartProps {
  data: {
    labels: string[];
    keyLabels: string[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datasets: any;
  };
  graph_id: string;
  onClick: (graph_id: string, dataset_label: string) => void;
}

export const DashboardTriggerLineChart = (
  props: DashboardTriggerLineChartProps,
) => {
  const emptyData = {
    labels: [],
    keyLabels: [],
    datasets: [],
  };

  const options = {
    indexAxis: 'y' as const,
    responsive: true,
    maintainAspectRatio: false,
    maxBarThickness: 20,
    layout: {
      padding: {
        left: 20,
        right: 20,
      },
    },
    barThickness: 'flex' as const,
    hoverBackgroundColor: getComputedStyle(document.body).getPropertyValue(
      '--color-secondary',
    ),
    backgroundColor: getComputedStyle(document.body).getPropertyValue(
      '--color-blue-200',
    ),
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: getComputedStyle(document.body).getPropertyValue(
          '--color-label-text',
        ),
        labels: {
          l1: {
            align: 'end' as const,
            formatter: (value, _) => {
              if (value) return commarize(value);
            },
          },
        },
        align: 'start' as const,
        anchor: 'end' as const,
        padding: 0,
        font: {
          size: 16,
          weight: 'bold' as const,
        },
      },
    },
    onHover: function (event, legendItem, _) {
      event.chart.canvas.parentNode.style.cursor = legendItem[0]
        ? 'pointer'
        : 'default';
    },
    onClick: function (e, chartElement) {
      if (chartElement.length <= 0) return;
      props.onClick(
        props.graph_id,
        props.data.keyLabels[chartElement[0].index],
      );
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
        offset: false,
      },

      y: {
        ticks: {
          callback: function (val, _) {
            const tickVal = this.getLabelForValue(val);
            if (tickVal.length > 25) return `${tickVal.substr(0, 25)}...`;

            return tickVal;
          },
          color: getComputedStyle(document.body).getPropertyValue(
            '--color-label-text',
          ),
          font: {
            size: 16,
          },
          padding: 5,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        categoryPercentage: 0.3, // Removes extra spacing between categories
        barPercentage: 1.0, // Ensures bars fill the available space
      },
    },
  };

  let chartHeight = 300;
  if (props.data && props.data.labels) {
    const numOfBars = props.data.labels.length;
    chartHeight = numOfBars >= 10 ? (400 * numOfBars) / 10 : 300;
  }

  return (
    <div
      className="relative"
      style={{
        height: chartHeight,
      }}
    >
      <Bar
        id={props.graph_id}
        data={props && props.data?.datasets ? props.data : emptyData}
        options={options}
      />
    </div>
  );
};

export default DashboardTriggerLineChart;
