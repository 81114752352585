import './index.scss';
import { inject, observer } from 'mobx-react';
import { Empty } from 'antd';
import { commarize, toTitleCase } from '../../utils/general';

import { LoadingSpinner } from '../AiFunctionalComponents';

const NetworkDNAData = (props) => {
  const { count, value, label } = props;
  return (
    <div
      style={{
        borderLeft: '2px solid var(--color-blue-200)',
        padding: '0px 16px',
      }}
    >
      <div className="dna-count">{commarize(count)} </div>
      <div className="dna-label">{value ? toTitleCase(value) : ''}</div>
      <div className="dna-label-description">{label}</div>
    </div>
  );
};

const NetworkDNANew = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props) => {
    const {
      industry,
      salary,
      title,
      city,
      stockPerformance,
      company,
      wealthSegment,
      zipCodes,
      states,
      colleges,
      isLoading,
    } = props;

    return (
      <div
        className={`network-dna-new dashboard-item rounded-16 flex`}
        style={{ padding: '44px', gap: '20px' }}
      >
        <div className="flex flex-col" style={{ flexGrow: 0, maxWidth: '25%' }}>
          <div className="header">Network DNA</div>
          <span className="header-description">
            Discover strengths in your Network DNA and leverage for success.
            Expand your network through teams and discover more.
          </span>
        </div>
        <div
          className={`grid grid-cols-1 sm:grid-cols-2 mdlg:grid-cols-4 relative ${isLoading ? 'aid-div-disable' : 'aid-div-enable'}`}
          style={{
            flexGrow: 1,
            gap: '32px 12px',
          }}
        >
          {<LoadingSpinner isLoading={isLoading} />}

          {!(
            (industry &&
              industry.top_industries &&
              industry.top_industries.length > 0) ||
            (stockPerformance &&
              stockPerformance.top_stock_performance &&
              stockPerformance.top_stock_performance.length > 0) ||
            (company &&
              company.top_ai_org_ids &&
              company.top_ai_org_ids.length > 0) ||
            (title && title.top_titles && title.top_titles.length > 0) ||
            (wealthSegment &&
              wealthSegment.top_wealth_estimate &&
              wealthSegment.top_wealth_estimate.length > 0) ||
            (salary &&
              salary.top_salary_ranges &&
              salary.top_salary_ranges.length > 0) ||
            (zipCodes &&
              zipCodes.top_zip_codes &&
              zipCodes.top_zip_codes.length > 0) ||
            (city && city.top_cities && city.top_cities.length > 0) ||
            (states && states.top_states && states.top_states.length > 0) ||
            (colleges &&
              colleges.top_colleges &&
              colleges.top_colleges.length > 0)
          ) ? (
            <div className="dna-empty">
              <Empty />
            </div>
          ) : (
            <>
              {industry &&
                industry.top_industries &&
                industry.top_industries.length > 0 && (
                  <NetworkDNAData
                    count={industry.top_industries[0].unique_count}
                    value={industry.top_industries[0].key}
                    label={'Industries'}
                  />
                )}
              {stockPerformance &&
                stockPerformance.top_stock_performance &&
                stockPerformance.top_stock_performance.length > 0 && (
                  <NetworkDNAData
                    count={
                      stockPerformance.top_stock_performance[0].unique_count
                    }
                    value={stockPerformance.top_stock_performance[0].key}
                    label={'Company Viability'}
                  />
                )}
              {company &&
                company.top_ai_org_ids &&
                company.top_ai_org_ids.length > 0 && (
                  <NetworkDNAData
                    count={company.top_ai_org_ids[0].unique_count}
                    value={company.top_ai_org_ids[0].key}
                    label={'Companies'}
                  />
                )}
              {title && title.top_titles && title.top_titles.length > 0 && (
                <NetworkDNAData
                  count={title.top_titles[0].unique_count}
                  value={title.top_titles[0].key}
                  label={'Title Rank'}
                />
              )}
              {wealthSegment &&
                wealthSegment.top_wealth_estimate &&
                wealthSegment.top_wealth_estimate.length > 0 && (
                  <NetworkDNAData
                    count={wealthSegment.top_wealth_estimate[0].unique_count}
                    value={wealthSegment.top_wealth_estimate[0].key}
                    label={'Wealth Segment'}
                  />
                )}
              {salary &&
                salary.top_salary_ranges &&
                salary.top_salary_ranges.length > 0 && (
                  <NetworkDNAData
                    count={salary.top_salary_ranges[0].unique_count}
                    value={salary.top_salary_ranges[0].key}
                    label={'Inferred Salary Ranges'}
                  />
                )}
              {zipCodes &&
                zipCodes.top_zip_codes &&
                zipCodes.top_zip_codes.length > 0 && (
                  <NetworkDNAData
                    count={zipCodes.top_zip_codes[0].unique_count}
                    value={zipCodes.top_zip_codes[0].key}
                    label={'Zip Codes'}
                  />
                )}
              {city && city.top_cities && city.top_cities.length > 0 && (
                <NetworkDNAData
                  count={city.top_cities[0].unique_count}
                  value={city.top_cities[0].key}
                  label={'Cities'}
                />
              )}
              {states && states.top_states && states.top_states.length > 0 && (
                <NetworkDNAData
                  count={states.top_states[0].unique_count}
                  value={states.top_states[0].key}
                  label={'States & Territories'}
                />
              )}
              {colleges &&
                colleges.top_colleges &&
                colleges.top_colleges.length > 0 && (
                  <NetworkDNAData
                    count={colleges.top_colleges[0].unique_count}
                    value={colleges.top_colleges[0].key}
                    label={'Colleges/Universities'}
                  />
                )}
            </>
          )}
        </div>
      </div>
    );
  }),
);

export default NetworkDNANew;
