import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';

import { message, Select, Empty } from 'antd';

import { ColumnsType, TableProps } from 'antd/es/table';
import moment from 'moment';
import CardsIcon from '../../assets/icons/ai_cards.svg?react';
import ListIcon from '../../assets/icons/ai_ic_list.svg?react';
import CheckIcon from '../../assets/icons/ai_ic_check.svg?react';

import {
  ConnectionStrength,
  LoadingSpinner,
  PremiumLabel,
} from '../AiFunctionalComponents';
import { formatEventName, formatValue } from '../../utils/general';
import {
  hasEntitlement,
  isPremiumWealthTrigger,
  isOpportunityPurchased,
} from '../../utils/entitlements';

import { requestWithAuth } from '../../services/api';
import { showCompanyProfile, showProfile } from '../../utils/modals';
import { FunctionFlags } from '../../utils/constants';

import {
  ModalDataStore,
  Opportunity,
  SortInfoInterface,
  DataStore,
  GlobalDataStore,
  Tag,
} from '../../types/GlobalTypes';

import { AiButton } from '../AiButton';
import AiTable from '../AiTable';
import BlurredContent from '../BlurredContent';
import OpportunityCardNew from './OpportunityCardNew';

interface DashboardOpportunitiesProps {
  dataStore: DataStore;
  modalDataStore: ModalDataStore;
  globalDataStore: GlobalDataStore;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isLoading: boolean;
}

type GroupedEvents = {
  last72Hours: { key: string; opportunities: Opportunity[] };
  lastWeek: { key: string; opportunities: Opportunity[] };
  last2Weeks: { key: string; opportunities: Opportunity[] };
  lastMonth: { key: string; opportunities: Opportunity[] };
};

message.config({
  top: 80,
});

const sortInfo: SortInfoInterface = {
  order: '',
};

const DashboardOpportunities = inject(
  'dataStore',
  'globalDataStore',
  'modalDataStore',
)(
  observer((props: DashboardOpportunitiesProps) => {
    const { data, isLoading } = props;
    const [displayType, setDisplayType] = useState<string>('card');
    const [viewBy, setViewBy] = useState<string>('trigger');
    const [previewCount, setPreviewCount] = useState<number>(5);
    const [user, setUser] = useState({ name: '', crm: '', org_name: '' });
    const [tags, setTags] = useState([]);

    const hasPremWealthTriggers = hasEntitlement(
      props.dataStore.currentUser,
      FunctionFlags.premium_wealth_triggers,
    );

    useEffect(() => {
      const { crm, org_name, full_name } = props.dataStore.currentUser;
      setUser({
        name: full_name || '',
        crm: crm || '',
        org_name: org_name || '',
      });

      fetchUserTags();
    }, [props.dataStore.currentUser]);

    const groupedTriggers = useMemo(
      () => (): GroupedEvents => {
        const opps = Object.values(data).flat() as Opportunity[];

        const now = new Date();
        now.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison

        // Helper function to calculate the difference in days
        const daysDifference = (dateStr: string): number => {
          const eventDate = new Date(dateStr);
          const diffTime = now.getTime() - eventDate.getTime();
          return Math.floor(diffTime / (1000 * 3600 * 24));
        };

        // Initialize buckets
        const groupedEvents: GroupedEvents = {
          last72Hours: { key: 'Past 72 Hours', opportunities: [] },
          lastWeek: { key: 'Past Week', opportunities: [] },
          last2Weeks: { key: 'Past 2 Weeks', opportunities: [] },
          lastMonth: { key: 'Past Month', opportunities: [] },
        };

        // Get the date 1 month ago
        const oneMonthAgo = new Date(now);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

        // Iterate over events and group them into buckets
        for (const opportunity of opps) {
          const event = opportunity.events;

          const diffInDays = daysDifference(event.event_date);
          const eventDate = new Date(event.event_date);

          if (diffInDays <= 3) {
            // Last 72 hours
            groupedEvents.last72Hours.opportunities.push(opportunity);
          } else if (diffInDays <= 7) {
            // Last week (4-7 days)
            groupedEvents.lastWeek.opportunities.push(opportunity);
          } else if (diffInDays <= 14) {
            // Last 2 weeks (8-14 days)
            groupedEvents.last2Weeks.opportunities.push(opportunity);
          } else if (eventDate >= oneMonthAgo) {
            // Last month (15 days to 1 month)
            groupedEvents.lastMonth.opportunities.push(opportunity);
          }
        }

        return groupedEvents;
      },
      [data],
    );

    useEffect(() => {
      // TODO: save user filters for this page
    }, [displayType, viewBy, previewCount]);

    const fetchUserTags = () => {
      requestWithAuth('tags', 'GET', null, null, null)
        .then((response) => {
          if (response && response.length) {
            setTags([...response]);
          }
        })
        .catch((e) => {
          console.error('error fetching tags', e);
        });
    };

    const columns: ColumnsType<Opportunity> = [
      {
        title: 'Name',
        dataIndex: 'full_name',
        width: 100,
        render: (val: string, record: Opportunity) => {
          const isPremTrigger = isPremiumWealthTrigger(
            record.events.event_type,
          );
          const entiteldToContent = isOpportunityPurchased(
            props.dataStore.currentUser,
            record,
          );
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              <BlurredContent blurred={isPremTrigger && !entiteldToContent}>
                {val}
              </BlurredContent>
            </div>
          );
        },
      },
      {
        title: 'Title',
        dataIndex: 'title',
        width: 120,
        render: (val: string, record: Opportunity) => {
          const isPremTrigger = isPremiumWealthTrigger(
            record.events.event_type,
          );
          const entiteldToContent = isOpportunityPurchased(
            props.dataStore.currentUser,
            record,
          );

          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              <BlurredContent blurred={isPremTrigger && !entiteldToContent}>
                {val}
              </BlurredContent>
            </div>
          );
        },
      },
      {
        title: 'Location',
        dataIndex: 'location_state',
        width: 49,
        render: (val: string) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val}
            </div>
          );
        },
      },
      {
        title: 'Trigger Type',
        dataIndex: ['events', 'event_type'],
        key: 'tigger_type',
        width: 90,
        render: (val: string, record: Opportunity) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              <div className="flex items-center">
                {formatEventName(val)}
                {!hasPremWealthTriggers &&
                isPremiumWealthTrigger(record.events.event_type) ? (
                  <PremiumLabel
                    icon={true}
                    outline={false}
                    iconColor="premium-green"
                    bgColor="transparent"
                    label={false}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: 'Trigger Description',
        dataIndex: ['events', 'event_description'],
        width: 120,
        render: (val: string) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val}
            </div>
          );
        },
      },
      {
        title: 'Trigger Value',
        dataIndex: ['events', 'event_monetary_value'],
        width: 70,
        render: (val: string) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val ? formatValue(val) : ''}
            </div>
          );
        },
      },
      {
        title: 'Trigger Date',
        dataIndex: ['events', 'event_date'],
        width: 70,
        render: (val: string) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              <div>{moment(val).format('MM/DD/YYYY')}</div>
            </div>
          );
        },
        sorter: {
          compare: (a, b) => {
            const aD = moment(a.events.event_date, 'YYYY-MM-DD');
            const bD = moment(b.events.event_date, 'YYYY-MM-DD');

            if (aD.isBefore(bD)) return -1;
            if (aD.isAfter(bD)) return 1;
            return 0;
          },
        },
        sortDirections: ['ascend', 'descend', 'ascend'],
      },
      {
        title: 'Connection Strength',
        dataIndex: 'opportunity_score',
        width: 91,
        render: (val: number) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {/* TODO: the strength needs re-worked... this is not the correct way to determine it */}
              <ConnectionStrength
                short={true}
                strength={val > 200 ? 'high' : val < 119 ? 'low' : 'med'}
              />
            </div>
          );
        },
      },
      {
        title: 'Introducers',
        dataIndex: 'mutual_count',
        width: 58,
        render: (val: number) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
              }}
            >
              {val}
            </div>
          );
        },
      },
    ];

    function handleOnProfileClick(b2bId, b2cId) {
      if (!b2bId && !b2cId) {
        message.warning(
          'This profile cannot be opened right now. Please try again later.',
          10,
        );
        return;
      }

      showProfile(b2bId, b2cId);
    }

    async function handleCreateTag(tagName: string): Promise<Tag> {
      if (!tagName || tagName === 'Tag name already exists') return;
      const body = { tag: tagName };

      return await requestWithAuth('tags', 'POST', null, body, null).then(
        (response) => {
          if (response && response.code === 200 && response.tag) {
            const tagObj = {
              ...response.tag,
              owner_id: response.tag.owner,
            } as Tag;

            setTags((prev) => [...prev, tagObj]);

            return tagObj;
          } else if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.detail &&
            response.response.data.detail === 'Tag name already exists'
          ) {
            return { tag: 'Tag name already exists' } as Tag;
          }
        },
      );
    }

    const defaultItemStyles: React.CSSProperties = useMemo(
      () => ({
        borderRadius: 0,
        padding: '12px 16px',
        borderStyle: 'solid',
        borderWidth: '0px 0px 1px 0px',
        borderColor: '#DCE5EB',
      }),
      [],
    );

    const lastItemStyles: React.CSSProperties = useMemo(
      () =>
        Object.assign({}, defaultItemStyles, {
          borderRadius: '0px 0px 4px 4px',
        }),
      [defaultItemStyles],
    );

    const firstItemStyles: React.CSSProperties = useMemo(
      () =>
        Object.assign({}, defaultItemStyles, {
          borderRadius: '4px 4px 0px 0px',
        }),
      [defaultItemStyles],
    );

    const renderViewByDropdown = () => {
      const options = [
        { label: 'Trigger Type', value: 'trigger', style: firstItemStyles },
        { label: 'Trigger Date', value: 'date', style: lastItemStyles },
      ];

      const handleChange = (value: string) => {
        setViewBy(value);
      };

      return (
        <Select
          id="view-by"
          popupClassName="wealth-trigger-filter"
          onChange={handleChange}
          optionRender={(option) => (
            <div className="flex items-center justify-between">
              <span>{option.label}</span>
              {option.value == viewBy ? (
                <CheckIcon width={20} height={20} />
              ) : (
                <></>
              )}
            </div>
          )}
          style={{ width: 170 }}
          options={options}
          defaultValue={viewBy}
        />
      );
    };

    const renderPreviewCountropdown = () => {
      const options = [
        { label: '5 per category', value: 5, style: firstItemStyles },
        { label: '10 per category', value: 10, style: defaultItemStyles },
        { label: '20 per category', value: 20, style: defaultItemStyles },
        { label: '30 per category', value: 30, style: lastItemStyles },
      ];

      const handleChange = (value: number) => {
        console.log('handlechange', value);
        setPreviewCount(value);
      };

      return (
        <Select
          id="preview-count"
          popupClassName="wealth-trigger-filter"
          onChange={handleChange}
          optionRender={(option) => (
            <div className="flex items-center justify-between">
              <span>{option.label}</span>
              {option.value == previewCount ? (
                <CheckIcon width={20} height={20} />
              ) : (
                <></>
              )}
            </div>
          )}
          style={{ width: 170 }}
          options={options}
          defaultValue={previewCount}
        />
      );
    };

    const renderHeader = () => {
      return (
        <div className="flex flex-col md:flex-row items-center w-full">
          <div className="grow">
            <span className="header">New Wealth Triggers</span>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-20">
            <div className="flex items-center gap-4 margin-t-auto">
              <AiButton
                buttonStyle={{
                  padding: '4px',
                  borderRadius: '4px',
                  backgroundColor:
                    displayType == 'card' ? '#808184' : 'inherit',
                }}
                buttonclassName={
                  displayType == 'card' ? 'wealth-trigger-svg-fill' : ''
                }
                buttonType="icon"
                onClick={() => setDisplayType('card')}
              >
                <CardsIcon width={20} height={20} />
              </AiButton>

              <AiButton
                buttonStyle={{
                  padding: '4px',
                  borderRadius: '4px',
                  backgroundColor:
                    displayType == 'list' ? '#808184' : 'inherit',
                }}
                buttonType="icon"
                buttonclassName={
                  displayType == 'list' ? 'wealth-trigger-svg-fill' : ''
                }
                onClick={() => setDisplayType('list')}
              >
                <ListIcon width={24} height={24} />
              </AiButton>
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="view-by"
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22px',
                }}
              >
                View By
              </label>
              {renderViewByDropdown()}
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="preview-count"
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '22px',
                }}
              >
                Preview Count
              </label>
              {renderPreviewCountropdown()}
            </div>
          </div>
        </div>
      );
    };

    const renderOpportunityCard = (eventData, premTrigger) => {
      const entiteldToContent = isOpportunityPurchased(
        props.dataStore.currentUser,
        eventData,
      );

      return (
        <OpportunityCardNew
          data={eventData}
          showProfile={handleOnProfileClick}
          user={user}
          showTriggerType={viewBy == 'date'}
          entitledToPremiumContent={
            premTrigger == false ||
            (premTrigger == true && entiteldToContent == true)
          }
          createTag={handleCreateTag}
          addReminder={(isReminder, value, b2bId, b2cId) =>
            console.debug('no-oop')
          }
          toggleProgress={(isLoading) =>
            // TODO: handle crm export progress per event trigger type
            // toggleProgress(isLoading, opportunity[0])
            console.debug(
              'toggle progress',
              isLoading,
              '\r\noppData',
              eventData,
            )
          }
          showCompanyProfile={showCompanyProfile}
          tags={tags}
        />
      );
    };

    const renderEventTitle = (event, eventArray) => {
      return (
        <div className="event-title">
          <div className="title-rectangle-left">
            {/* TODO: add icon to collapse an event segment */}

            {formatEventName(event)}
            {isPremiumWealthTrigger(event) && (
              <PremiumLabel fill={true} icon={!hasPremWealthTriggers} />
            )}
            <div className="title-triangle-left"></div>
          </div>
          <div className="title-rectangle-right">
            {eventArray.length === 1
              ? `1 total opportunity`
              : `${eventArray.length} total opportunities`}

            {/* TODO: add link to Prospect Finder for the event trigger */}
          </div>
        </div>
      );
    };

    const renderOpportunityByTrigger = (event, eventArray) => {
      const premTrigger = isPremiumWealthTrigger(event);
      return (
        <div key={event} className="flex flex-col relative gap-8">
          {renderEventTitle(event, eventArray)}
          {/* render by card */}
          {displayType == 'card' &&
            renderOpportunityCardGrid(eventArray, premTrigger)}

          {/* render by table */}
          {displayType == 'list' && renderOpportunitiesTable(eventArray)}
        </div>
      );
    };

    const sortOpportunities = (opportunities: Array<Opportunity>) => {
      if (viewBy == 'trigger')
        return opportunities.sort((a, b) => {
          if (a.opportunity_score !== b.opportunity_score)
            return b.opportunity_score - a.opportunity_score;

          const dateA = moment(a.events.event_date, 'YYYY-MM-DD');
          const dateB = moment(b.events.event_date, 'YYYY-MM-DD');
          return dateB.diff(dateA);
        });
      if (viewBy == 'date')
        return opportunities.sort((a, b) => {
          const dateA = moment(a.events.event_date, 'YYYY-MM-DD');
          const dateB = moment(b.events.event_date, 'YYYY-MM-DD');
          if (dateA !== dateB) return dateB.diff(dateA);

          return b.opportunity_score - a.opportunity_score;
        });

      return opportunities;
    };

    const renderOpportunityCardGrid = (
      opportunities: Opportunity[],
      premTrigger?: boolean,
    ) => {
      const premTrigProvided = typeof premTrigger !== 'undefined';

      if (opportunities.length == 0) return <></>;

      // handle the sort of the opportunities based on the view by setting.
      opportunities = sortOpportunities(opportunities);

      return (
        <div
          className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 opportunities-grid"
          style={{ paddingLeft: 20, gap: 16 }}
        >
          {opportunities.slice(0, previewCount).map((oppData) => {
            if (!premTrigProvided)
              premTrigger = isPremiumWealthTrigger(oppData.events.event_type);

            return renderOpportunityCard(oppData, premTrigger);
          })}
        </div>
      );
    };

    const renderOpportunitiesTable = (opportunities: Opportunity[]) => {
      // handle the sort of the opportunities based on the view by setting.
      opportunities = sortOpportunities(opportunities);

      // this is kind of "hacked together"... we want paging, but we don't want to show
      // the pagination options at the bottom of the table. so, we set the paging options
      // to show our previewCount size, then use css to hide the pagination controls
      return (
        <AiTable
          className="opportunity-table"
          rowKey={(rec) => `${rec.events.event_id}`}
          data={opportunities}
          pagination={{
            current: 1,
            pageSize: previewCount,
            total: opportunities.length,
          }}
          size="small"
          columns={
            viewBy == 'date'
              ? columns
              : columns.filter((c) => c.key != 'tigger_type')
          }
          scroll={{ x: 'max-content', y: 400 }}
        />
      );
    };

    const renderOpportunityByDate = () => {
      const events = groupedTriggers();

      return Object.entries(events).map(
        ([_, value]: [
          keyof GroupedEvents,
          GroupedEvents[keyof GroupedEvents],
        ]) => {
          if (value.opportunities.length == 0) return <></>;

          return (
            <div className="flex flex-col relative gap-8">
              {renderEventTitle(value.key, value.opportunities)}

              {/* render by card */}
              {displayType == 'card' &&
                renderOpportunityCardGrid(value.opportunities)}

              {/* render by table */}
              {displayType == 'list' &&
                renderOpportunitiesTable(value.opportunities)}
            </div>
          );
        },
      );
    };

    const renderEventTriggers = () => {
      return (
        <div className="flex flex-col" style={{ gap: 40 }}>
          {viewBy == 'trigger' &&
            Object.entries(data).map((opportunity) => {
              const events = opportunity[1] as Array<Opportunity>;
              const event = opportunity[0];

              if (events.length == 0) return <></>;

              return renderOpportunityByTrigger(event, events);
            })}

          {viewBy == 'date' && renderOpportunityByDate()}

          {(Object.entries(data).length === 0 ||
            Object.values(data).flat().length == 0) && (
            <div
              className="flex items-center justify-center w-full"
              style={{ paddingTop: '15px' }}
            >
              <Empty description={`No New Wealth Triggers in last 30 days`} />
            </div>
          )}
        </div>
      );
    };

    return (
      <div
        className={`
          flex flex-col
          w-full 
          relative 
          rounded-16 
          justify-between
          ${isLoading ? 'aid-div-disable' : 'aid-div-enable'}`}
        style={{
          padding: '44px 40px',
          background: 'var(--color-white)',
        }}
      >
        <LoadingSpinner isLoading={isLoading} />
        {renderHeader()}
        {renderEventTriggers()}
      </div>
    );
  }),
);

export default DashboardOpportunities;
