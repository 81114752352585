import './index.scss';
import { DragDropContainer } from 'react-drag-drop-container';
import { dashboardBoxItems } from '../../utils/constants';
import { PremiumLabel } from '../AiFunctionalComponents';

const DragDropListItem = (props) => {
  const { id, onClick, targetKey, noDragging = false } = props;

  return (
    <div>
      <DragDropContainer
        targetKey={targetKey}
        dragData={{ label: id }}
        disappearDraggedElement={false}
        noDragging={noDragging}
        zIndex={2000}
      >
        <div
          id={id}
          className={`drag-drop-list-item ${props.draggableClassName}`}
          onClick={onClick}
        >
          <span className="flex items-center" style={{ gap: '4px' }}>
            {dashboardBoxItems[id]}
            {noDragging && (
              <PremiumLabel
                label={false}
                outline={false}
                iconColor="premium-green"
                bgColor="transparent"
              />
            )}
          </span>
        </div>
      </DragDropContainer>
    </div>
  );
};

export default DragDropListItem;
