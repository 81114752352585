import './index.scss';

import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Empty } from 'antd';

import { DataStore, ModalDataStore } from '../../types/GlobalTypes';

import { DashboardTriggerLineChart } from '../Chart/DashboardTriggerLineChart';

import { LoadingSpinner } from '../AiFunctionalComponents';
import { formatEventName } from '../../utils/general';

interface EventData {
  event_type: string;
  event_count: number;
  event_value: number;
  order: number;
  org_count: number;
  first_degree_count: number;
  second_degree_count: number;
}

interface TriggersOverviewParams {
  eventData: EventData[];
  isLoading: boolean;
  dataStore: DataStore;
  modalDataStore: ModalDataStore;
  onClick: (
    graph_id: string,
    dataset_label: string,
    from_wealth_triggers?: boolean,
  ) => void;
  onCloseButtonClick?: () => void;
}

const TriggersOverview = inject(
  'dataStore',
  'modalDataStore',
)(
  observer((props: TriggersOverviewParams) => {
    const { eventData, isLoading } = props;
    const [hasData, setHasData] = useState(false);
    const [chartData, setChartData] = useState({
      labels: [],
      keyLabels: [],
      datasets: [],
    });

    useEffect(() => {
      const labels: string[] = [];
      const keyLabels: string[] = [];
      const data: (number | [number, number])[] = [];

      setHasData(eventData.length != 0);

      eventData
        .sort((a, b) => {
          if (a.event_count !== b.event_count) {
            return b.event_count - a.event_count;
          }
          return a.order - b.order;
        })
        .forEach((event) => {
          labels.push(formatEventName(event.event_type));
          keyLabels.push(event.event_type);
          data.push(event.event_count);
        });

      setChartData({
        labels: labels,
        keyLabels: keyLabels,
        datasets: [
          {
            data: data,
          },
        ],
      });
    }, [eventData]);

    const renderEmpty = () => {
      return (
        <div className="flex w-full items-center justify-center">
          <Empty />
        </div>
      );
    };

    const renderBarChart = () => {
      return (
        <DashboardTriggerLineChart
          onClick={(graph_id: string, dataset_label: string) => {
            props.onClick(graph_id, dataset_label, true);
          }}
          graph_id={'triggers_overview'}
          data={chartData}
        />
      );
    };

    const renderHeader = () => {
      return <span className="header">Wealth Trigger Statistics</span>;
    };

    return (
      <div
        className={`
          flex flex-col
          w-full 
          relative 
          rounded-16 
          justify-between
          ${isLoading ? 'aid-div-disable' : 'aid-div-enable'}`}
        style={{
          padding: '44px 40px',
          gap: '20px',
          background: 'var(--color-white)',
        }}
      >
        <LoadingSpinner isLoading={isLoading} />
        {renderHeader()}
        {hasData ? renderBarChart() : renderEmpty()}
      </div>
    );
  }),
);

export default TriggersOverview;
