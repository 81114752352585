import { useState } from 'react';
import { Radio, Table, Empty } from 'antd';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import SearchIcon from '../../assets/icons/ai_ic_link_out.svg?react';
import ListIcon from '../../assets/icons/ai_ic_list.svg?react';
import ListIconActive from '../../assets/icons/ai_ic_list_active.svg?react';
import CardsIcon from '../../assets/icons/ai_ic_cards.svg?react';
import CardsIconActive from '../../assets/icons/ai_ic_cards_active.svg?react';
import HotLinkIcon from '../../assets/icons/ai_ic_up_arrow.svg?react';

import AidHorizontalScrollList from '../AidHorizontalScrollList';

import filterStates from '../../utils/Statelist';
import {
  commarize,
  formatValue,
  formatEventName,
  formatEventSource,
  formatEventDescription,
  formatInvestor,
} from '../../utils/general';

import {
  isPremiumWealthTrigger,
  isEntitledToPremiumContent,
} from '../../utils/entitlements';
import BlurredContent from '../BlurredContent';
import { PremiumLabel } from '../AiFunctionalComponents';
import { FunctionFlags } from '../../utils/constants';

const EventsCard = (props) => {
  const item = props.listItem;

  const flags = useFlags();
  const entitlementsActive = flags && flags.dntfd9919GtmEntitlements;
  const isPremium = isPremiumWealthTrigger(item.event_type);
  const blurContent =
    entitlementsActive && isPremium && !props.hasPremiumWealthTriggers;

  const renderEventName = (event) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {entitlementsActive && (
          <PremiumLabel
            bgColor={'transparent'}
            icon={blurContent}
            iconColor={blurContent ? 'premium-green' : 'white'}
            outline={true}
            labelColor={'premium-green'}
          />
        )}

        {event.event_type && formatEventName(event.event_type)}
      </div>
    );
  };

  const renderExternalLink = (event, display_type) => {
    const isPremium = isPremiumWealthTrigger(event.event_type);
    if (!entitlementsActive || !isPremium)
      return showSearch(event, display_type);

    if (isPremium && props.hasPremiumWealthContent) {
      return showSearch(event, display_type);
    }
  };

  return (
    <div
      className="list-card"
      onClick={() => {
        if (blurContent) return false;

        onCardClick(item, props.showProfile);
      }}
    >
      <div className="event-title">
        <BlurredContent blurred={blurContent}>
          {renderEventName(item)}
        </BlurredContent>
        {renderExternalLink(item)}
      </div>
      <div className="description-text">
        {item.event_description && (
          <BlurredContent blurred={blurContent}>
            {formatEventDescription(item.event_description, item.event_type)}
            {!item.event_type.includes('property sale') &&
              !item.event_type.includes('property listing') && (
                <>
                  {item.event_type === 'news'
                    ? item.event_domain
                      ? ` (Source: ${item.event_domain})`
                      : ''
                    : item.event_source || item.event_data_source_name
                      ? ` (Source: ${formatEventSource(
                          item.event_source || item.event_data_source_name,
                        )})`
                      : ''}
                </>
              )}
          </BlurredContent>
        )}
        {flags &&
          flags.dntfd9217DeceasedTriggers &&
          item &&
          item.event_type === 'deceased' &&
          item.event_min_value !== null &&
          item.event_min_value !== undefined &&
          item.event_min_value >= 0 &&
          item.event_max_value && (
            <BlurredContent blurred={blurContent}>
              <div>
                Wealth Segment: ${commarize(item.event_min_value, 0)} - $
                {commarize(item.event_max_value, 0)}
              </div>
            </BlurredContent>
          )}
        {item.event_type === 'news' && item.event_context && (
          <div dangerouslySetInnerHTML={{ __html: item.event_context }} />
        )}
        <div>
          {item.event_funding_round && (
            <BlurredContent blurred={blurContent}>
              <>
                {' '}
                <span style={{ color: 'var(--color-grey-dark)' }}>
                  Investment Round{': '}
                </span>
                {item.event_funding_round}
              </>
            </BlurredContent>
          )}
        </div>
        <div>
          {item.event_investor_name && (
            <BlurredContent blurred={blurContent}>
              <>
                {' '}
                <span style={{ color: 'var(--color-grey-dark)' }}>
                  Investors{': '}
                </span>
                {formatInvestor(item.event_investor_name)}
              </>
            </BlurredContent>
          )}
        </div>
      </div>
      <div className="card-bottom">
        <div>
          {item.event_monetary_value !== undefined &&
            item.event_monetary_value !== null &&
            item.event_type !== 'news' && (
              <BlurredContent blurred={blurContent}>
                <div
                  style={{
                    // fontSize: '21px',
                    color: 'var(--color-header-blue-variant)',
                    paddingRight: '9px',
                  }}
                >
                  {formatValue(item.event_monetary_value)}
                </div>
              </BlurredContent>
            )}
          {item.event_type === 'deceased' && props.hasHouseHoldMembers && (
            <div
              className="flex pointer"
              onClick={(e) => onHouseholdClick(e)}
              style={{ paddingTop: '15px' }}
            >
              <HotLinkIcon />
              See Household Members
            </div>
          )}
        </div>
        {item.event_date && (
          <div style={{ color: 'var(--color-column)' }}>
            {moment(item.event_date).format('MM/DD/YYYY')}
            {item.event_state && item.event_type !== 'news' && (
              <>, {item.event_state}</>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const showSearch = (item, displayType) => {
  /** Show search icon for non-insider or insider with filing url
   * show link for news with filing url
   * Don't show icon or link for Company/title change
   */
  if (
    !item.event_type ||
    item.event_type === 'company change' ||
    item.event_type === 'job title change' ||
    item.event_type === 'property sale' ||
    item.event_type === 'property purchase' ||
    item.event_type === 'property listing'
  )
    return <></>;

  if (item.event_type && item.event_type === 'news' && item.event_filing_url) {
    return (
      <div
        style={{
          textAlign: displayType === 'list' ? 'left' : 'right',
          width: '100%',
          fontWeight: 400,
        }}
      >
        <a href={item.event_filing_url} target="_blank" rel="noreferrer">
          <SearchIcon
            className={
              displayType === 'list' ? 'search-icon' : 'search-icon-card'
            }
          />
        </a>
      </div>
    );
  }

  if (
    item.event_type &&
    (!item.event_type.includes('insider') || item.event_filing_url)
  ) {
    return (
      <SearchIcon
        className={displayType === 'list' ? 'search-icon' : 'search-icon-card'}
      />
    );
  }
};

const onCardClick = (item, showProfile) => {
  if (
    !item.event_type ||
    item.event_type === 'company change' ||
    item.event_type === 'job title change' ||
    item.event_type === 'news' ||
    item.event_type === 'property sale' ||
    item.event_type === 'property purchase' ||
    item.event_type === 'property listing'
  )
    return;

  if (item.event_filing_url) {
    if (
      item.event_type.includes('insider') ||
      item.event_type === 'investment received'
    ) {
      window.open(item.event_filing_url);
    }
  } else if (item.event_type == 'deceased') {
    /** Show deceased profile */

    const relatedProfile = (
      item.event_related_profiles ? Array.from(item.event_related_profiles) : []
    )[0];

    if (relatedProfile && showProfile) {
      showProfile(relatedProfile.b2b_id, relatedProfile.b2c_id);
    }
  } else if (!item.event_type.includes('insider')) {
    const searchValue = `${item.event_org_name} , ${item.event_type}`;
    window.open(`https://www.google.com/search?q=${searchValue}`);
  }
};

const onHouseholdClick = (e) => {
  e.stopPropagation();
  const element = document.getElementById('household-member-dropdown');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    element.click();
  }
};

const Triggers = inject('dataStore')(
  observer((props) => {
    const [displayType, setDisplayType] = useState('cards');
    const [showAllCards, setShowAllCards] = useState(false);

    const [tableList] = useState(props.list || []);

    const flags = useFlags();
    const entitlementsActive = flags && flags.dntfd9919GtmEntitlements;

    const { currentUser } = props.dataStore;
    const hasPremiumWealthTriggers = isEntitledToPremiumContent(
      currentUser,
      props.person,
      FunctionFlags.premium_wealth_triggers,
    );

    const rowKeyArray = [];

    const renderEventName = (event) => {
      if (!entitlementsActive) {
        return <span>{formatEventName(event.event_type)}</span>;
      }

      const isPremium = isPremiumWealthTrigger(event.event_type);

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isPremium && !hasPremiumWealthTriggers && (
            <PremiumLabel
              iconColor="premium-green"
              labelText="PREMIUM WEALTH TRIGGER"
              bgColor="transparent"
              outline={true}
              borderColor="premium-green"
              labelColor="black"
            />
          )}
          {isPremium && hasPremiumWealthTriggers && event.event_type && (
            <div className="flex flex-col items-center">
              <PremiumLabel
                bgColor="transparent"
                labelColor="premium-green"
                outline={true}
                borderColor="premium-green"
                icon={false}
              />
              <span>{formatEventName(event.event_type)}</span>
            </div>
          )}
        </div>
      );
    };

    const renderExternalLink = (event, display_type) => {
      const isPremium = isPremiumWealthTrigger(event.event_type);
      if (!entitlementsActive || !isPremium)
        return showSearch(event, display_type);

      if (isPremium && hasPremiumWealthTriggers) {
        return showSearch(event, display_type);
      }
    };

    const columns = [
      {
        title: 'Trigger',
        dataIndex: 'event_type',
        key: 'event_type',
        width: entitlementsActive && !hasPremiumWealthTriggers ? 240 : 140,

        render: (val, rec) => {
          if (val === undefined || val === null) return '';

          /* Setting id as unique key array */
          rowKeyArray.push(rec.event_id);

          return (
            <div
              className="event-type-header items-center"
              style={{ display: val === 'news' ? 'block' : 'flex' }}
              onClick={() => {
                const isPremium = isPremiumWealthTrigger(rec.event_type);
                if (
                  entitlementsActive &&
                  isPremium &&
                  !hasPremiumWealthTriggers
                )
                  return false;

                onCardClick(rec, props.showProfile);
              }}
            >
              {renderEventName(rec)}
              {renderExternalLink(rec, displayType)}
            </div>
          );
        },

        filters: props.dataStore.lookUpLists.eventList.slice(1), // exclude All from eventList
        filterMultiple: true,
        onFilter: (value, record) => record.event_type === value,
        sorter: (a, b) => {
          a = a.event_type || '';
          b = b.event_type || '';
          return a.localeCompare(b);
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        width: 200,
        dataIndex: 'event_description',
        className: 'column-right',
        key: 'event_description',
        render: (val, rec) => {
          if (rec.event_id === undefined || rec.event_id === null) return '';
          const blurContent =
            entitlementsActive &&
            isPremiumWealthTrigger(rec.event_type) &&
            !hasPremiumWealthTriggers;

          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-profile-labels)',
              }}
            >
              <BlurredContent blurred={blurContent}>
                {formatEventDescription(val, rec.event_type)}
                {rec.event_type === 'news' && rec.event_context && (
                  <div>{rec.event_context}</div>
                )}
              </BlurredContent>
              <div>
                {rec.event_investor_name && (
                  <BlurredContent blurred={blurContent}>
                    {' '}
                    <span style={{ color: 'var(--color-grey-dark)' }}>
                      Investors{': '}
                    </span>
                    {formatInvestor(rec.event_investor_name)}
                  </BlurredContent>
                )}
              </div>
              {rec.event_type === 'deceased' && props.hasHouseHoldMembers && (
                <div
                  className="flex pointer"
                  onClick={(e) => onHouseholdClick(e)}
                >
                  <HotLinkIcon />
                  See Household Members
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Source',
        width: 120,
        dataIndex: 'event_source',
        render: (val, rec) => {
          if (rec.event_id === undefined || rec.event_id === null || !val)
            return '';

          const blurContent =
            entitlementsActive &&
            isPremiumWealthTrigger(rec.event_type) &&
            !hasPremiumWealthTriggers;

          return (
            <BlurredContent blurred={blurContent}>
              <div
                style={{
                  width: '100%',
                  wordBreak: 'break-word',
                  display: 'block',
                  color: 'var(--color-profile-labels)',
                }}
              >
                {rec.event_type === 'news'
                  ? rec.event_domain || ''
                  : val
                    ? formatEventSource(val)
                    : ''}
              </div>
            </BlurredContent>
          );
        },
      },
      {
        title: 'Value',
        width: 130,
        dataIndex: 'event_monetary_value',
        className: 'column-right',
        key: 'event_monetary_value',
        sorter: hasPremiumWealthTriggers
          ? (a, b) => a.event_monetary_value - b.event_monetary_value
          : false,
        sortDirections: ['descend', 'ascend'],
        render: (val, rec) => {
          const blurContent =
            entitlementsActive &&
            isPremiumWealthTrigger(rec.event_type) &&
            !hasPremiumWealthTriggers;

          if (
            rec.event_min_value !== null &&
            rec.event_min_value !== undefined &&
            rec.event_min_value >= 0 &&
            rec.event_max_value
          )
            return (
              <BlurredContent blurred={blurContent}>
                <div
                  style={{
                    width: '100%',
                    wordBreak: 'break-word',
                    display: 'block',
                    color: 'var(--color-dark)',
                  }}
                >
                  ${commarize(rec.event_min_value, 0)} - $
                  {commarize(rec.event_max_value, 0)}
                </div>
              </BlurredContent>
            );

          return (
            <BlurredContent blurred={blurContent}>
              <div
                style={{
                  width: '100%',
                  wordBreak: 'break-word',
                  display: 'block',
                  color: 'var(--color-dark)',
                }}
              >
                {val === null || rec.event_type === 'news'
                  ? ''
                  : formatValue(val)}
              </div>
            </BlurredContent>
          );
        },
      },

      {
        title: 'Date',
        width: 110,
        dataIndex: 'event_date',
        key: 'event_date',
        render: (val) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-dark)',
              }}
            >
              {moment(val).format('MM/DD/YYYY')}
            </div>
          );
        },
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          a = a.event_date || '';
          b = b.event_date || '';
          return a.localeCompare(b);
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Trigger State',
        width: 150,
        dataIndex: 'event_state',
        key: 'event_state',
        sortDirections: ['ascend', 'descend'],
        onFilter: (value, record) =>
          value && record.event_state
            ? record.event_state.indexOf(value) === 0
            : true,
        sorter: (a, b) => {
          a = a.event_state || '';
          b = b.event_state || '';
          return a.localeCompare(b);
        },
        render: (val, rec) => {
          return (
            <div
              style={{
                width: '100%',
                wordBreak: 'break-word',
                display: 'block',
                color: 'var(--color-dark)',
              }}
            >
              {rec.event_type === 'news' ? '' : val}
            </div>
          );
        },
        filters: filterStates,
      },
    ];

    const cardColumns = [
      {
        title: (
          <div style={{ paddingLeft: 20 }}>
            <span
              style={{
                fontSize: 16,
                color: 'var(--color-black)',
                fontFamily: 'Poppins-Regular',
                paddingRight: '20px',
                fontWeight: 500,
              }}
            >
              Sort by:{' '}
            </span>
            Trigger
          </div>
        ),
        dataIndex: 'event_type',
        key: 'event_type',
        width: 230,
        filters: props.dataStore.lookUpLists.eventList.slice(1), // exclude All from eventList
        filterMultiple: true,
        onFilter: (value, record) => record.event_type === value,
        sorter: (a, b) => {
          a = a.event_type || '';
          b = b.event_type || '';
          return a.localeCompare(b);
        },
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Value',
        dataIndex: 'event_monetary_value',
        className: 'column-right',
        width: 100,
        key: 'event_monetary_value',
        sorter: hasPremiumWealthTriggers
          ? (a, b) => a.event_monetary_value - b.event_monetary_value
          : false,
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Date',
        dataIndex: 'event_date',
        key: 'event_date',
        width: 120,
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
          a = a.event_date || '';
          b = b.event_date || '';
          return a.localeCompare(b);
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Trigger State',
        width: 180,
        dataIndex: 'event_state',
        key: 'event_state',
        sortDirections: ['ascend', 'descend'],
        onFilter: (value, record) =>
          value && record.event_state
            ? record.event_state.indexOf(value) === 0
            : true,
        sorter: (a, b) => {
          a = a.event_state || '';
          b = b.event_state || '';
          return a.localeCompare(b);
        },
        filters: filterStates,
      },
      {
        title: '',
        width: 'auto',
      },
    ];

    const displayArray = [
      {
        label: 'Cards',
        value: 'cards',
        icon:
          displayType === 'cards' ? (
            <CardsIconActive className="toggle-icon-active" />
          ) : (
            <CardsIcon className="toggle-icon" />
          ),
      },
      {
        label: 'List',
        value: 'list',
        icon:
          displayType === 'list' ? (
            <ListIconActive className="toggle-icon-active" />
          ) : (
            <ListIcon className="toggle-icon" style={{ marginBottom: '0px' }} />
          ),
      },
    ];

    return (
      <div className="triggers-section break-inside-avoid">
        <div className="triggers-header profile-box-header">
          <div className="profile-titles">
            Wealth Triggers {tableList.length > 0 && <>({tableList.length})</>}
          </div>
          <div className="display-buttons">
            <Radio.Group
              onChange={(e) => {
                setDisplayType(e.target.value);
              }}
              value={displayType}
            >
              {displayArray.map((display) => {
                return (
                  <Radio.Button value={display.value}>
                    <>{display.icon}</>
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </div>
        </div>
        <div>
          {displayType === 'list' ? (
            <Table
              rowKey={(obj) => obj.event_id}
              dataSource={tableList}
              columns={columns}
              pagination={false}
              scroll={{ x: 'max-content', y: 350 }}
              showSorterTooltip={false}
            />
          ) : (
            <div className="event-cards-section">
              <Table
                rowKey={(obj) => obj.event_id}
                dataSource={tableList}
                columns={cardColumns}
                pagination={false}
                scroll={{ x: 'max-content', y: 350 }}
                showSorterTooltip={false}
              />

              {
                // showMore?
                tableList && tableList.length > 0 ? (
                  showAllCards ? (
                    <>
                      <div className="events-card-body">
                        <div
                          style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}
                        >
                          {tableList.map((listItem) => {
                            return (
                              <EventsCard
                                listItem={listItem}
                                hasHouseHoldMembers={props.hasHouseHoldMembers}
                                showProfile={props.showProfile}
                                hasPremiumWealthTriggers={
                                  hasPremiumWealthTriggers
                                }
                              />
                            );
                          })}
                        </div>
                        <div
                          className="show-more no-print"
                          onClick={() => {
                            setShowAllCards(false);
                          }}
                        >
                          Show Less
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="events-card-body">
                      <AidHorizontalScrollList rows={1} cardWidth={320}>
                        {tableList.map((listItem) => {
                          return (
                            <EventsCard
                              listItem={listItem}
                              hasHouseHoldMembers={props.hasHouseHoldMembers}
                              showProfile={props.showProfile}
                              hasPremiumWealthTriggers={
                                hasPremiumWealthTriggers
                              }
                            />
                          );
                        })}
                      </AidHorizontalScrollList>
                      <div
                        className="show-more no-print"
                        onClick={() => {
                          setShowAllCards(true);
                        }}
                      >
                        Show All
                      </div>
                    </div>
                  )
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )
              }
            </div>
          )}
        </div>
      </div>
    );
  }),
);

export default Triggers;
