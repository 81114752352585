import { Table, TablePaginationConfig, TableProps } from 'antd';
import './index.scss';

interface AiPaginationProps {
  current: number;
  pageSize: number;
  total: number;
}

interface AiTableProps<Type extends object> {
  rowKey: string | ((record: Type) => string);
  data: Type[];
  columns: TableProps<Type>['columns'];
  pagination: boolean | AiPaginationProps;
  onChange?: TableProps<Type>['onChange'];
  className?: string;
  scroll?: TableProps<Type>['scroll'];
  size?: TableProps<Type>['size'];
}

const AiTable = <Type extends object>({
  rowKey,
  data,
  columns,
  pagination,
  onChange = () => {},
  className = '',
  size = 'large',
  scroll,
}: AiTableProps<Type>) => {
  const hasPagination = typeof pagination !== 'boolean';
  const antdPagination: false | TablePaginationConfig = hasPagination
    ? {
        pageSizeOptions: ['20', '50', '100', '200'],
        simple: {
          readOnly: true,
        },
        showSizeChanger: {
          showSearch: false,
          variant: 'borderless',
          popupMatchSelectWidth: 50,
          placement: 'topRight',
          labelRender: ({ value }) => <>{value}</>,
          optionRender: ({ value }) => <>{value}</>,
          prefix: 'Results per page: ',
        },
        itemRender: (page, type, originalElement) => {
          if (type === 'prev') {
            return (
              <div className="flex">
                {typeof pagination === 'object' && pagination.current === 1 ? (
                  <></>
                ) : (
                  originalElement
                )}{' '}
                <span style={{ pointerEvents: 'none', marginTop: '-2px' }}>
                  Page
                </span>
              </div>
            );
          }
          return originalElement;
        },
        ...(typeof pagination === 'object' ? pagination : {}),
      }
    : false;

  return (
    <Table<Type>
      bordered
      className={`custom-ai-table ${className}`}
      rowKey={rowKey}
      size={size}
      dataSource={data}
      columns={columns}
      pagination={antdPagination}
      onChange={onChange}
      showSorterTooltip={false}
      scroll={scroll}
    />
  );
};

export default AiTable;
